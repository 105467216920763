
import Vue from 'vue';
import { modeMixin } from '@/mixins';
import { eValidationRules, isFormValid } from '@/validation';
import { eCustomFieldTypes } from '@/enums';
import { endpoints, STANDARD_UPLOAD_LIMIT_IN_BYTES } from '@/constants';
import { getLocalStorageItem, setLocalStorageItem } from '@/store';
import SitchFileUpload from '@/components/SitchFileUpload.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import SignaturePad from 'signature_pad';
import { standardApiFetch } from '@/util-functions/api-utils';
import { t } from '@/util-functions/initialization-util';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { reinitializeState, scrollToTop } from '@/util-functions/misc-utils';
import { showError } from '@/util-functions/notice-utils';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

function initialState(): {
  name: string;
  email: string;
  phoneNumber: string;
  customFieldValues: Record<string, CustomFieldValue>;
  wasSentSuccessfully: boolean;
  eCustomFieldTypes: typeof eCustomFieldTypes;
  signaturePad: any;
  showSignaturePadModal: boolean;
  currSignatureCustomFieldId: string;
  currSignatureStatement: string;
  formattedAddressMap: {
    [customFieldId: string]: string;
  };
  formattedAddressLine2Map: {
    [customFieldId: string]: string;
  };
} {
  return {
    name: getLocalStorageItem('name') || '',
    email: getLocalStorageItem('email') || '',
    phoneNumber: getLocalStorageItem('phoneNumber') || '',
    customFieldValues: {},
    wasSentSuccessfully: false,
    eCustomFieldTypes,
    signaturePad: null,
    showSignaturePadModal: false,
    currSignatureCustomFieldId: '',
    currSignatureStatement: '',
    formattedAddressMap: {},
    formattedAddressLine2Map: {},
  };
}

export default Vue.extend({
  components: { VueGoogleAutocomplete, SitchFileUpload },
  mixins: [modeMixin],
  props: {
    isPreSubmissionForm: {
      type: Boolean,
      default: false,
    },
    isPostSubmissionForm: {
      type: Boolean,
      default: false,
    },
    isForPaymentSitch: {
      type: Boolean,
      default: false,
    },
    isForBookingSitch: {
      type: Boolean,
      default: false,
    },
    isEmbeddedInModal: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    isEmbedded(): boolean {
      return this.isPreSubmissionForm || this.isPostSubmissionForm;
    },
  },
  watch: {
    email() {
      setLocalStorageItem('email', this.email);
    },
    name() {
      setLocalStorageItem('name', this.name);
    },
    phoneNumber() {
      setLocalStorageItem('phoneNumber', this.phoneNumber);
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    reset() {
      reinitializeState(this.$data, initialState);
      this.onLoad();
    },
    onLoad() {
      const canvas = document.querySelector('#signature-canvas') as HTMLCanvasElement;
      if (canvas) {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = 300 * ratio;
        canvas.height = 150 * ratio;
        canvas.getContext('2d')?.scale(ratio, ratio);
        this.signaturePad = new SignaturePad(canvas, {});
      }
    },
    showSignaturePad(customField: CustomField) {
      this.currSignatureCustomFieldId = customField.id;
      this.currSignatureStatement = customField.signatureStatement;
      this.showSignaturePadModal = true;
      this.clearSignature();
    },
    hideSignaturePad() {
      this.currSignatureCustomFieldId = '';
      this.currSignatureStatement = '';
      this.showSignaturePadModal = false;
    },
    clearSignature() {
      if (!this.signaturePad) {
        return;
      }
      this.signaturePad.clear();
    },
    saveSignature() {
      if (!this.signaturePad) {
        showError('Signature pad failed to initialize.');
        return;
      }
      this.customFieldValues[this.currSignatureCustomFieldId] = {
        statement: this.currSignatureStatement,
        image: this.signaturePad.toDataURL(),
      };
      this.hideSignaturePad();
    },
    setAddressData(addressData: any, place: any, customFieldId: string) {
      this.formattedAddressMap[customFieldId] = place.formatted_address || '';
      this.customFieldValues[customFieldId] = this.formattedAddressMap[customFieldId];
      const googleAutoComplete: any = document.getElementById(`google-autocomplete-${customFieldId}`);
      if (googleAutoComplete) {
        googleAutoComplete.value = '';
      }
      this.buildAddress(customFieldId);
    },
    appendAddressLine2(e: any, customFieldId: string) {
      this.formattedAddressLine2Map[customFieldId] = e.target.value || '';
      this.buildAddress(customFieldId);
    },
    onGoogleAutoCompleteBlur(customFieldId: string) {
      const googleAutoComplete: any = document.getElementById(`google-autocomplete-${customFieldId}`);
      if (googleAutoComplete) {
        googleAutoComplete.value = '';
      }
    },
    buildAddress(customFieldId: string) {
      if (this.formattedAddressLine2Map[customFieldId]) {
        this.customFieldValues[customFieldId] = `${this.formattedAddressLine2Map[customFieldId]}, ${this.formattedAddressMap[customFieldId] || ''}`;
      } else {
        this.customFieldValues[customFieldId] = this.formattedAddressMap[customFieldId] || '';
      }
      this.$forceUpdate();
    },
    clearAddress(customFieldId: string) {
      this.formattedAddressMap[customFieldId] = '';
      this.$forceUpdate();
    },
    onGoogleAutocompleteError(error: any) {
      showError(error?.message);
    },
    convertStringArrayToTextValueArray(array: string[]): TextValue[] {
      return array.map((string) => ({
        text: string,
        value: string,
      }));
    },
    getInputType(type: eCustomFieldTypes): string {
      switch (type) {
        case eCustomFieldTypes.email:
          return 'email';
        case eCustomFieldTypes.phoneNumber:
          return 'tel';
        case eCustomFieldTypes.url:
          return 'url';
        case eCustomFieldTypes.shortText:
          return 'text';
        case eCustomFieldTypes.number:
          return 'number';
        case eCustomFieldTypes.date:
          return 'date';
        case eCustomFieldTypes.time:
          return 'time';
        case eCustomFieldTypes.dateTime:
          return 'datetime-local';
      }
      return '';
    },
    setReview(fieldId: string, rating: number) {
      this.customFieldValues[fieldId] = rating;
      this.onFormChange();
      this.$forceUpdate();
    },
    onFormChange() {
      if (this.isPreSubmissionForm) {
        this.sendFormDetails();
      }
    },
    getCustomFormValidationAndData(quietValidation = false) {
      const mode: CustomFormMode = (this as any).mode;
      const customFieldsFormRules: any = [];
      const customFields = mode.customFields;
      const customFieldData: {
        [id: string]: CustomFieldData;
      } = {};
      const nameMapping: Record<string, string> = {};

      customFields.forEach((customField) => {
        const currValue: CustomFieldValue = this.customFieldValues[customField.id];
        // If falsy but not explicitely false.
        if (customField.isRequired) {
          switch (customField.type) {
            case eCustomFieldTypes.email:
              customFieldsFormRules.push([eValidationRules.requiredEmailRules, [customField.id]]);
              break;
            case eCustomFieldTypes.phoneNumber:
              customFieldsFormRules.push([eValidationRules.requiredPhoneRules, [customField.id]]);
              break;
            case eCustomFieldTypes.url:
              customFieldsFormRules.push([eValidationRules.requiredUrlRules, [customField.id]]);
              break;
            case eCustomFieldTypes.shortText:
              customFieldsFormRules.push([eValidationRules.requiredGenericStringRules, [customField.id]]);
              break;
            case eCustomFieldTypes.longText:
              customFieldsFormRules.push([eValidationRules.requiredGenericLongStringRules, [customField.id]]);
              break;
            case eCustomFieldTypes.checkbox:
              // Never needs to be validated.
              break;
            case eCustomFieldTypes.address:
              customFieldsFormRules.push([eValidationRules.requiredCustomFormAddress, [customField.id]]);
              break;
            default:
              customFieldsFormRules.push([eValidationRules.required, [customField.id]]);
              break;
          }
        } else {
          switch (customField.type) {
            case eCustomFieldTypes.email:
              customFieldsFormRules.push([eValidationRules.emailRules, [customField.id]]);
              break;
            case eCustomFieldTypes.phoneNumber:
              customFieldsFormRules.push([eValidationRules.phoneRules, [customField.id]]);
              break;
            case eCustomFieldTypes.url:
              customFieldsFormRules.push([eValidationRules.urlRules, [customField.id]]);
              break;
            case eCustomFieldTypes.shortText:
              customFieldsFormRules.push([eValidationRules.genericStringRules, [customField.id]]);
              break;
            case eCustomFieldTypes.longText:
              customFieldsFormRules.push([eValidationRules.genericLongStringRules, [customField.id]]);
              break;
          }
        }

        const customFieldValue: CustomFieldData = {
          name: customField.name,
          type: customField.type,
          value: currValue as CustomFieldValue,
        };
        nameMapping[customField.id] = customField.name;
        customFieldData[customField.id] = customFieldValue;
      });

      const areCustomFieldsValid = isFormValid(this, this.customFieldValues, customFieldsFormRules, quietValidation, nameMapping);

      return { areCustomFieldsValid, customFieldData };
    },
    sendFormDetails() {
      const mode: CustomFormMode = (this as any).mode;
      const dataFormRules: any = [];

      if (mode.requireName) {
        dataFormRules.push([eValidationRules.requiredGenericStringRules, ['name']]);
      }
      if (mode.requireEmail) {
        dataFormRules.push([eValidationRules.requiredEmailRules, ['email']]);
      }
      if (mode.requirePhoneNumber) {
        dataFormRules.push([eValidationRules.requiredPhoneRules, ['phoneNumber']]);
      }

      const { areCustomFieldsValid, customFieldData } = this.getCustomFormValidationAndData(Boolean(this.isPreSubmissionForm));

      // Only do validations here if not an embedded pre submission form.
      if (!this.isPreSubmissionForm) {
        const isValid = isFormValid(this, this.$data, dataFormRules);
        if (!isValid) {
          return;
        }
        if (!areCustomFieldsValid) {
          return;
        }
      }

      const customFormData: CustomFormSubmission = {
        name: mode.requireName ? this.name : '',
        email: mode.requireEmail ? this.email : '',
        phoneNumber: mode.requirePhoneNumber ? this.phoneNumber : '',
        customFields: customFieldData,
        userId: this.$store.state.userId,
        modeId: mode.docId,
      };

      if (this.isPreSubmissionForm) {
        this.$emit('change', customFormData.customFields);
      } else {
        const uploadFilesForCustomForm = (customFieldsInSubmission: { [id: string]: CustomFieldData }) => {
          // If custom fields have file uploads we have to upload them.
          const date = new Date();
          const allUploadedUrls: string[] = [];
          const fbStorage = getStorage();

          function rollBack() {
            allUploadedUrls.forEach((fileUrl) => {
              deleteObject(ref(fbStorage, fileUrl)).catch((error: any) => {
                showError(`Unable to complete rollback for uploaded files. ${error?.message || error}`, true);
              });
            });
          }

          const storagePath = `customFormFiles/${this.$store.state.userId}/${date.getFullYear()}-${date.getMonth()}`;
          showLoading();
          // For each custom field where the value is a file upload, create a promise.
          const storageFileArray: StorageFile[] = [];
          const allUploadFieldPromises = Object.values(customFieldsInSubmission)
            .filter((field) => field.type === eCustomFieldTypes.fileUpload)
            .map((uploadFileField) => {
              const compressedFiles = uploadFileField.value as CompressedFile[];
              const allFileUploadPromisesForCurrentField: Promise<void>[] =
                compressedFiles?.map((compressedFile: CompressedFile) => {
                  return new Promise((uploadResolve, uploadReject) => {
                    const file = compressedFile.file;
                    const fullPath = `${storagePath}/${compressedFile.id}_${compressedFile.fileName}`;

                    const uploadTask = uploadBytesResumable(ref(fbStorage, fullPath), file);

                    if (file.size < STANDARD_UPLOAD_LIMIT_IN_BYTES) {
                      uploadTask.then((uploadTaskSnapshot) => {
                        getDownloadURL(uploadTaskSnapshot.ref)
                          .then((downloadUrl: string) => {
                            // Push the uploaded image into data.
                            storageFileArray.push({
                              id: compressedFile.id,
                              mimeType: compressedFile.file.type,
                              fileName: compressedFile.fileName,
                              storagePath,
                              downloadUrl,
                              base64ImagePreview: compressedFile.base64ImagePreview || '',
                              isHidden: false,
                            });
                            allUploadedUrls.push(downloadUrl);
                            uploadResolve();
                          })
                          .catch((error: any) => {
                            showError(`Unable to get the download URL for ${storagePath}. ${error?.message || error}`, true);
                            uploadReject();
                          });
                      });
                    } else {
                      showError(t.fileTooLarge);
                      uploadReject();
                    }
                  });
                }) || [];
              return Promise.all(allFileUploadPromisesForCurrentField).then(() => {
                uploadFileField.value = storageFileArray;
              });
            });
          return Promise.all(allUploadFieldPromises)
            .catch(() => {
              rollBack();
            })
            .finally(() => {
              hideLoading();
            });
        };

        uploadFilesForCustomForm(customFormData.customFields).then(() => {
          standardApiFetch(endpoints.customFormSubmit, customFormData).then(() => {
            this.wasSentSuccessfully = true;
            this.onSubmit();
            Vue.nextTick(() => {
              scrollToTop();
            });
          });
        });
      }
    },
  },
});
