
import Vue from 'vue';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import { mapState } from 'vuex';
import { eModeType, eSnackBarTypes, eTranslationLocaleCode } from './enums';
import { routerNavigate, eventBus, getAndSetUrlData, getMode, getParameterByName } from './util-functions/initialization-util';
import languages from './languages';
import { getLocalStorageItem, setLocalStorageItem } from './store';
import { addHttp, delay, getModeIcon, getPayServiceLink, getVenmoLink, shareCurrentPage } from './util-functions/misc-utils';

const navigator: any = window.navigator;

function mobileCheck() {
  let check = false;
  const typedWindow: any = window;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || typedWindow.opera);
  return check;
}

export default Vue.extend({
  components: {
    'hamburger-menu': HamburgerMenu,
  },
  data(): {
    routes: HamburgerRoute[];
    eModeType: typeof eModeType;
    processingLinkAction: boolean;
    showingOrderInfo: boolean;
    isMobile: boolean;
    showSnackbar: boolean;
    snackBarMessage: string;
    snackBarType: eSnackBarTypes;
    eSnackBarTypes: typeof eSnackBarTypes;
    isMounted: boolean;
    showGlobalModal: boolean;
    globalModalTitle: string;
    showGlobalModalModalMsg: string;
    showConfirmationModal: boolean;
    showLanguageModal: boolean;
    showHistoryModal: boolean;
    showDeprecatedModeMessage: boolean;
    confirmationModalTitle: string;
    confirmationModalMsg: string;
    confirmationModalQueue: {
      confirmationModalTitle: string;
      confirmationModalMsg: string;
      currentConfirmationModalAffirmativeFunc: () => void;
      currentConfirmationModalNegativeFunc: () => void;
    }[];
    currentConfirmationModalAffirmativeFunc: () => void;
    currentConfirmationModalNegativeFunc: () => void;
    languages: typeof languages;
    embeddedFullscreen: boolean;
    isFirstNavigation: boolean;
    recentModes: HistoryMode[];
  } {
    return {
      showDeprecatedModeMessage: false,
      languages,
      routes: [],
      eModeType,
      processingLinkAction: document.location.pathname.toLowerCase().includes('/linkactions'),
      showingOrderInfo: document.location.pathname.toLowerCase().includes('/transactionsummary'),
      isMobile: mobileCheck(),
      showSnackbar: false,
      snackBarMessage: '',
      snackBarType: eSnackBarTypes.notice,
      eSnackBarTypes,
      isMounted: false,
      showGlobalModal: false,
      globalModalTitle: '',
      showGlobalModalModalMsg: '',
      showConfirmationModal: false,
      showLanguageModal: false,
      showHistoryModal: false,
      confirmationModalTitle: '',
      confirmationModalMsg: '',
      confirmationModalQueue: [],
      currentConfirmationModalAffirmativeFunc: (): void => undefined,
      currentConfirmationModalNegativeFunc: (): void => undefined,
      embeddedFullscreen: false,
      isFirstNavigation: true,
      recentModes: JSON.parse(getLocalStorageItem('recentModes') || '[]'),
    };
  },
  computed: {
    ...mapState(['visitedRoot', 'settingEmbededMode', 'isPremiumActive', 'isLoading', 'isEmbedded', 'embeddedWidth', 'mode', 'finishedModeAcquisitionProcess', 'locale']),
    modeType(): string {
      return this.$store.state.mode?.type || '';
    },
    deviceWidth(): number {
      return window.innerWidth;
    },
    teamThemeInUse(): boolean {
      return Boolean((this.$store.state.publicUserModeGateway as PublicUserModeGateway).assignedTeamThemeId);
    }
  },
  mounted() {
    this.onLoad();

    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        switch (event.data?._sitch_messageType) {
          case '_sitch_newSitchUrl':
            this.switchModeForEmbeddedSite(event.data.value); // event.value is a sitch.app url in this case.
            break;
        }
      },
      false
    );

    this.isMounted = true;
    this.postMessageMounted();

    const isSitchShop =
      location.href.includes('https://sitch.app/Shop?p=store') ||
      location.href.includes('https://sitch.app/Shop?p=ca-store') ||
      location.href.includes('https://sitch.app/Shop?p=uk-store') ||
      location.href.includes('https://sitch.app/Shop?p=eu-store') ||
      location.href.includes('https://sitch.app/Shop?p=int-store') ||
      location.href.includes('https://sitch.app/store/?e=true') ||
      location.href.includes('https://sitch.app/ca-store/?e=true') ||
      location.href.includes('https://sitch.app/uk-store/?e=true') ||
      location.href.includes('https://sitch.app/eu-store/?e=true') ||
      location.href.includes('https://sitch.app/int-store/?e=true');

    if (isSitchShop) {
      // const anyWindow = window as any;
      // anyWindow._mfq = anyWindow._mfq || [];
      // (function () {
      //   var mf = document.createElement('script');
      //   mf.type = 'text/javascript';
      //   mf.defer = true;
      //   mf.src = '//cdn.mouseflow.com/projects/ebcf1436-c54a-4412-ba18-7b10034822dd.js';
      //   document.getElementsByTagName('head')[0].appendChild(mf);
      // })();
      // ((
      //   function (h: any, o: any, t: any, j: any, a: any, r: any) {
      //     h.hj =
      //       h.hj ||
      //       function () {
      //         (h.hj.q = h.hj.q || []).push(arguments);
      //       };
      //     h._hjSettings = { hjid: 2712498, hjsv: 6 };
      //     a = o.getElementsByTagName('head')[0];
      //     r = o.createElement('script');
      //     r.async = 1;
      //     r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      //     a.appendChild(r);
      //   } as any
      // )(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='));
    }

    window.addEventListener('popstate', () => {
      eventBus.$emit('popstate');
      getAndSetUrlData();
      // Next tick so that state changes from the url changes can happen before we set the mode.
      Vue.nextTick(() => {
        if (this.$store.state.sitePages.length) {
          const currMode = this.$store.state.sitePages.find((sitePage: AnyMode) => sitePage.docId === getParameterByName('sp'));
          if (currMode.docId !== this.$store.state.mode?.docId) {
            this.$store.commit('modeChangeWasDueToPopState', true);
            this.$store.commit('mode', currMode);
          }
        }
      });
    });
  },
  watch: {
    mode() {
      this.onLoad();
    },
  },
  methods: {
    shareCurrentPage,
    getModeIcon,
    switchModeForEmbeddedSite(newUrl: string) {
      this.embeddedFullscreen = false;
      this.$store.commit('settingEmbededMode', true);
      getMode(newUrl); // event.value is a sitch.app url in this case.
    },
    onLoad() {
      // If new site pages, create hamburger menu routes.
      this.routes = this.$store.state.sitePages.map((sitePageMode: AnyMode) => {
        const defaultFunc = () => {
          this.$store.commit('mode', sitePageMode);
        };
        return {
          onClick: () => {
            switch (sitePageMode.type) {
              case eModeType.urlRedirect: {
                const urlRedirectMode = sitePageMode as UrlRedirectMode;
                if (urlRedirectMode.putInIframe) {
                  defaultFunc();
                } else {
                  window.open(addHttp(urlRedirectMode.redirectUrl), '_blank');
                }
                break;
              }
              case eModeType.personalPayment: {
                const paymentMode = sitePageMode as PersonalPaymentsMode;
                // If just paypalEnabled.
                if (!paymentMode.interacEnabled && !paymentMode.bitcoinEnabled && !paymentMode.venmoEnabled && !paymentMode.cashAppEnabled) {
                  window.open(getPayServiceLink(paymentMode, paymentMode.paypalMeUrl), '_blank');
                  // If just cashAppEnabed.
                } else if (!paymentMode.interacEnabled && !paymentMode.bitcoinEnabled && !paymentMode.venmoEnabled && !paymentMode.paypalEnabled) {
                  window.open(getPayServiceLink(paymentMode, paymentMode.cashAppUrl), '_blank');
                  // If just venmoEnabled.
                } else if (!paymentMode.interacEnabled && !paymentMode.bitcoinEnabled && !paymentMode.cashAppEnabled && !paymentMode.paypalEnabled) {
                  window.open(getVenmoLink(paymentMode), '_blank');
                } else {
                  defaultFunc();
                }
                break;
              }
              default:
                defaultFunc();
            }
          },
          name: sitePageMode.displayName || sitePageMode.name,
          type: sitePageMode.type,
          id: sitePageMode.docId,
        };
      });

      // Navigate to the proper component page based on the new mode.
      this.navigateToProperPageForMode();
    },
    changeLanguage(localeCode: eTranslationLocaleCode) {
      setLocalStorageItem('locale', localeCode);
      this.$store.commit('locale', localeCode);
    },
    navigateToProperPageForMode() {
      this.showDeprecatedModeMessage = false;
      if (!this.$store.state.mode) {
        return;
      }
      switch (this.$store.state.mode.type) {
        case eModeType.profile:
          routerNavigate('/Profile');
          break;
        case eModeType.urlRedirect:
          routerNavigate('/UrlRedirect');
          break;
        case eModeType.article:
          routerNavigate('/Article');
          break;
        case eModeType.files:
          routerNavigate('/Files');
          break;
        case eModeType.personalPayment:
          routerNavigate('/PersonalPayment');
          break;
        case eModeType.businessPayment:
          routerNavigate('/BusinessPayment');
          break;
        case eModeType.wifi:
          routerNavigate('/Wifi');
          break;
        case eModeType.site:
          routerNavigate('/Site');
          break;
        case eModeType.linkList:
          routerNavigate('/LinkList');
          break;
        case eModeType.gallery:
          routerNavigate('/Gallery');
          break;
        case eModeType.group:
          routerNavigate('/Group');
          break;
        case eModeType.shop:
          routerNavigate('/Shop');
          break;
        case eModeType.customForm:
          routerNavigate('/CustomForm');
          break;
        case eModeType.html:
          routerNavigate('/Html');
          break;
        case eModeType.events:
          routerNavigate('/Events');
          break;
        case eModeType.booking:
          routerNavigate('/Booking');
          break;
        case eModeType.blog:
          routerNavigate('/Blog');
          break;
        case eModeType.accordion:
          routerNavigate('/Accordion');
          break;
        case eModeType.chat:
          routerNavigate('/Chat');
          break;
        case eModeType.returnTo:
          routerNavigate('/ReturnTo');
          break;
        case eModeType.chess:
          routerNavigate('/Chess');
          break;
        case eModeType.wordle:
          routerNavigate('/Wordle');
          break;
        case eModeType.trivia:
          routerNavigate('/Trivia');
          break;
        case eModeType.card:
          routerNavigate('/Card');
          break;
        default:
          this.showDeprecatedModeMessage = true;
          break;
      }
      this.isFirstNavigation = false;
    },
    showGlobalModalFunc(title: string, msg: string) {
      setTimeout(() => {
        // I don't know why I need this unfortunately. The showGlobalModalValue does not stay true without a delay.
        this.showGlobalModal = true;
        this.globalModalTitle = title;
        this.showGlobalModalModalMsg = msg;
      }, 100);
    },
    showConfirmationModalFunc(title: string, msg: string, onConfirmFunc = (): void => undefined, onCancelFunc = (): void => undefined) {
      if (this.showConfirmationModal) {
        // If we're already showing the confirmation modal.
        this.confirmationModalQueue.push({
          // Queue this confirmation modal to show up after the current one is closed.
          confirmationModalTitle: title,
          confirmationModalMsg: msg,
          currentConfirmationModalAffirmativeFunc: onConfirmFunc,
          currentConfirmationModalNegativeFunc: onCancelFunc,
        });
      } else {
        this.showConfirmationModal = true;
        this.confirmationModalTitle = title;
        this.confirmationModalMsg = msg;
        this.currentConfirmationModalAffirmativeFunc = onConfirmFunc;
        this.currentConfirmationModalNegativeFunc = onCancelFunc;
      }
    },
    onConfirmationYesClick() {
      this.currentConfirmationModalAffirmativeFunc();
      this.hideConfirmationDialog();
    },
    onConfirmationNoClick() {
      this.currentConfirmationModalNegativeFunc();
      this.hideConfirmationDialog();
    },
    hideConfirmationDialog() {
      this.showConfirmationModal = false;
      this.currentConfirmationModalAffirmativeFunc = (): void => undefined;
      if (this.confirmationModalQueue.length) {
        setTimeout(() => {
          const confirmationModalData = this.confirmationModalQueue.pop();
          if (!confirmationModalData) {
            return;
          }
          this.confirmationModalTitle = confirmationModalData.confirmationModalTitle;
          this.confirmationModalMsg = confirmationModalData.confirmationModalMsg;
          this.currentConfirmationModalAffirmativeFunc = confirmationModalData.currentConfirmationModalAffirmativeFunc;
          this.currentConfirmationModalNegativeFunc = confirmationModalData.currentConfirmationModalNegativeFunc;
        }, 500); // Open the next pop up in 500 milleseconds so that the user is alerted by the animation, rather than just injecting the already visible popup with new data.
      }
    },
    showSnackBarFunc(str: string, snackBarType: string) {
      if (!str) {
        return;
      }
      this.snackBarMessage = str;
      this.snackBarType = snackBarType as eSnackBarTypes;
      this.showSnackbar = true;
      delay(() => {
        this.showSnackbar = false;
      }, 4000);
    },
    postMessageFullScreen() {
      window.parent.postMessage('_sitch_fullscreen', '*');
      this.embeddedFullscreen = true;
    },
    postMessageShrink() {
      window.parent.postMessage('_sitch_shrink', '*');
      this.embeddedFullscreen = false;
    },
    postMessageClose() {
      window.parent.postMessage('_sitch_close', '*');
      this.embeddedFullscreen = false;
    },
    postMessageMounted() {
      window.parent.postMessage('_sitch_mounted', '*');
    },
  },
});
