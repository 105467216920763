import { apiUrl, t } from "./initialization-util";
import { hideLoading, showLoading } from "./loading-utils";
import { showError } from "./notice-utils";

export const standardApiFetch = (endpoint: string, body: any, showLoadingSpinner = true): Promise<SitchApiResponse> => {
  return new Promise((resolve, reject) => {
    function rejectAndShowError(sitchApiResponse: SitchApiErrorResponse) {
      switch (sitchApiResponse.error?.code) {
        case 'amount_too_small':
          showError(t.chargeAmountTooSmall);
          break;
        default:
          if (sitchApiResponse.errorMessage) {
            showError(sitchApiResponse.errorMessage);
          }
          break;
      }
      reject(sitchApiResponse);
      if (showLoadingSpinner) {
        hideLoading(true);
      }
    }

    if (showLoadingSpinner) {
      showLoading();
    }
    fetch(`${apiUrl}/${endpoint}`, {
      method: 'post',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(body),
    })
      .then((response) => {
        response
          .json()
          .then((jsonResponse) => {
            if (jsonResponse.error || jsonResponse.errorMessage) {
              rejectAndShowError(jsonResponse);
            } else {
              resolve(jsonResponse);
            }
          })
          .catch((error: any) => {
            rejectAndShowError({ error, errorMessage: `Error getting json from the response. ${error?.message || error}` });
          });
      })
      .catch((error: any) => {
        rejectAndShowError({ error, errorMessage: `Error making standard api fetch. ${error?.message || error}` });
      })
      .finally(() => {
        if (showLoadingSpinner) {
          hideLoading(true);
        }
      });
  });
};

export const blobApiFetch = (endpoint: string, body: any, callback: (jsonResponse: any) => void = () => undefined): Promise<void> => {
  showLoading();
  return fetch(`${apiUrl}/${endpoint}`, {
    method: 'post',
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(body),
  })
    .then((response) => {
      response
        .blob()
        .then(callback)
        .catch((error: any) => {
          showError('Error getting blob from the response. ' + error?.message);
        });
    })
    .catch((error: any) => {
      showError('Error making fetch call. ' + error?.message);
    })
    .finally(() => {
      hideLoading();
    });
};

export const fileDownload = (data: any, filename: string, mime: string, bom: any = undefined) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    (window.navigator as any).msSaveBlob(blob, filename);
  } else {
    const blobURL = (window.URL ? window.URL : window.webkitURL).createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }
};