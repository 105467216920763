import { defaultThemes } from '@/constants';
import { eCardStyle, eMetaTheme } from '@/enums';
import { store } from '@/store';

export const initTheme = (theme: AestheticTheme = defaultThemes.sitchLight) => {  
  // Sanitizing some inputs just in case someone tries to inject a malicious url into the query params and pass the link to a potential victim.
  theme.backgroundColor = theme.backgroundColor.replace('url', '');
  theme.accentColor = theme.accentColor.replace('url', '');

  const defaultThemeBackgroundColor = '#ededf7';
  const backgroundColor: string = theme.backgroundColor || defaultThemeBackgroundColor;
  const backgroundImage: string = theme.images?.[0]?.largeImageUrl ? `url('${theme.images[0].largeImageUrl}')` : 'none';
  const logoBackgroundImage: string = theme.secondaryImages?.[0]?.smallImageUrl ? `url('${theme.secondaryImages[0].smallImageUrl}')` : 'none';
  const accentColor: string = theme.accentColor || '#2f8ce2';
  let primaryFontName: string = theme.primaryFontName || 'Poppins';
  let headerFontName: string = theme.headerFontName || 'Poppins';
  const primaryFontVariant: FontVariant = theme.primaryFontVariant || 'regular';
  const headerFontVariant: FontVariant = theme.headerFontVariant || 'bold';
  const primaryFontScale: number = theme.primaryFontScale || 1;
  const headerFontScale: number = theme.headerFontScale || 1;
  const headerFontTransform: string = theme.uppercaseHeaderFont ? 'uppercase' : 'none';
  const isFlatCardStyle = theme.cardStyle === eCardStyle.flat;
  const isSharpCardStyle = theme.cardStyle === eCardStyle.sharp;
  const linkEl: any = document.createElement('link');
  linkEl.type = 'text/css';
  linkEl.rel = 'stylesheet';
  const primaryTextInsertionAndWeight = getGoogleFontsQueryInsertionAndFontWeight(primaryFontName, primaryFontVariant);
  const headerTextInsertionAndWeight = getGoogleFontsQueryInsertionAndFontWeight(headerFontName, headerFontVariant);
  let primaryTextInsertion = primaryTextInsertionAndWeight.fontInsertion;
  let headerTextInsertion = headerTextInsertionAndWeight.fontInsertion;
  const primaryFontWeight = primaryTextInsertionAndWeight.weight;
  const headerFontWeight = headerTextInsertionAndWeight.weight;
  const primaryFontItalics = primaryTextInsertionAndWeight.italics;
  const headerFontItalics = headerTextInsertionAndWeight.italics;
  store.commit('ambientText', theme.ambientText);
  let appendLinkEl = true;  

  function getGoogleFontsQueryInsertionAndFontWeight(
    fontName: string,
    fontVariant: FontVariant
  ): {
    fontInsertion: string;
    weight: number;
    italics: number;
  } {
    let weight = 400;
    let italics = false;
    let fontInsertion = fontName ? fontName.replace(' ', '+') : '';
    if (fontVariant === 'regular') {
      fontInsertion += `:ital,wght@0,${weight}`;
    } else if (fontVariant === 'italic') {
      fontInsertion += `:ital,wght@1,${weight}`;
      italics = true;
    } else if (fontVariant === 'bold') {
      weight = 700;
      fontInsertion += `:ital,wght@0,${weight}`;
    } else {
      weight = parseInt(fontVariant);
      italics = fontVariant.length > 3; // Font variants that are just 3 characters long are only weights, anything else includes italics.
      fontInsertion += italics ? `:ital,wght@1,${weight}` : `:ital,wght@0,${weight}`;
    }
    return { fontInsertion, weight, italics: Number(italics) };
  }

  if (primaryFontName === 'Poppins' && ['regular', 'bold'].includes(primaryFontVariant)) {
    // Since we already have Poppins in regular and bold we wouldn't want to load another.
    primaryTextInsertion = '';
    switch (primaryFontVariant) {
      case 'regular':
        primaryFontName = 'PoppinsRegular';
        break;
      case 'bold':
        primaryFontName = 'PoppinsBold';
        break;
    }
  }

  if (headerFontName === 'Poppins' && ['regular', 'bold'].includes(headerFontVariant)) {
    // Since we already have Poppins in regular and bold we wouldn't want to load another.
    headerTextInsertion = '';
    switch (headerFontVariant) {
      case 'regular':
        headerFontName = 'PoppinsRegular';
        break;
      case 'bold':
        headerFontName = 'PoppinsBold';
        break;
    }
  }

  const googleFontsUrl = 'https://fonts.googleapis.com/css2?family=';

  if (primaryTextInsertion) {
    if (primaryTextInsertion === headerTextInsertion || !headerTextInsertion) {
      // Exact same or just the primary font.
      linkEl.href = `${googleFontsUrl}${primaryTextInsertion}&display=swap`;
    } else if (primaryFontName === headerFontName) {
      // In this case the weight or italics are different but the fonts are the same. We have to build this into one famiy query param for the google fonts link.
      linkEl.href = `${googleFontsUrl}${primaryFontName}:ital,wght@${primaryFontItalics},${primaryFontWeight};${headerFontItalics},${headerFontWeight}&display=swap`;
    } else {
      // In this case it's different fonts.
      linkEl.href = `${googleFontsUrl}${primaryTextInsertion}&family=${headerTextInsertion}&display=swap`;
    }
  } else if (headerTextInsertion) {
    // Just the header font.
    linkEl.href = `${googleFontsUrl}${headerTextInsertion}&display=swap`;
  } else {
    // Just using the default fonts already included with the app in this case.
    appendLinkEl = false;
  }

  // Setting a theme color for Chrome.
  const metaEl: any = document.createElement('meta');
  metaEl.name = 'theme-color';
  metaEl.content = backgroundColor;

  const docEl = document.documentElement as HTMLElement;
  const docHead = document.head as HTMLElement;

  // These must be lowercase.
  const lightBackgroundColors = [
    '#ffebee',
    '#fce4ec',
    '#f3e5f5',
    '#ede7f6',
    '#e8eaf6',
    '#e3f2fd',
    '#e1f5fe',
    '#e0f7fa',
    '#e0f2f1',
    '#e8f5e9',
    '#f1f8e9',
    '#fffde7',
    '#fff8e1',
    '#fff3e0',
    '#fbe9e7',
    '#fafafa',
    '#eceff1',
    '#f5f5f5',
    '#ffcdd2',
    '#f8bbd0',
    '#e1bee7',
    '#d1c4e9',
    '#c5cae9',
    '#bbdefb',
    '#b3e5fc',
    '#b2ebf2',
    '#b2dfdb',
    '#c8e6c9',
    '#dcedc8',
    '#fff9c4',
    '#ffecb3',
    '#ffe0b2',
    '#ffccbc',
    '#d7ccc8',
    '#cfd8dc',
    '#fffde7',
    '#fff59d',
    '#fdb515',
    '#f9a825',
    defaultThemeBackgroundColor,
  ];

  const alpha1 = 0.1;
  const alpha2 = 0.25;
  const alpha3 = 0.6;

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)},` : '';
  }

  let contentBackgroundColor = '#fff';
  const hasBackgroundImage = backgroundImage !== 'none';  
  const metaThemeIsLightOrNone = hasBackgroundImage && (!theme.metaTheme || theme.metaTheme === eMetaTheme.light);

  // Reset properties to default for the case of AutoSitch theme initializations.
  docEl.style.setProperty('--backgroundColor', '#000');
  docEl.style.setProperty('--backgroundImage', 'none ');
  docEl.style.setProperty('--backgroundImageOpacity', '0.5');
  docEl.style.setProperty('--logoBackgroundImage', 'none');
  docEl.style.setProperty('--displayLogo', 'none');
  docEl.style.setProperty('--accentColor', '#2f8ce2');
  docEl.style.setProperty('--primaryFontName', 'PoppinsRegular');
  docEl.style.setProperty('--headerFontName', 'PoppinsBold');
  docEl.style.setProperty('--headerFontTransform', 'none');
  docEl.style.setProperty('--primaryFontWeight', 'normal');
  docEl.style.setProperty('--headerFontWeight', 'normal');
  docEl.style.setProperty('--primaryFontScale', '1');
  docEl.style.setProperty('--headerFontScale', '1');
  docEl.style.setProperty('--cardRadius', '50rem');
  docEl.style.setProperty('--mobileCardRadius', '20rem');
  docEl.style.setProperty('--stdBorderRadius', '8rem');
  docEl.style.setProperty('--lrgBorderRadius', '20rem');
  docEl.style.setProperty('--colorScheme', 'light');
  docEl.style.setProperty('--stdFontColor', '#333');
  docEl.style.setProperty('--scrollBarColor', 'rgba(0, 0, 0, 0.3)');
  docEl.style.setProperty('--contentBackgroundColor', 'rgba(255, 255, 255, 0.6)');
  docEl.style.setProperty('--inputBackgroundColor', 'rgba(255, 255, 255, 0.9)');
  docEl.style.setProperty('--strongBackgroundColor', 'rgba(255, 255, 255, 0.9)');
  docEl.style.setProperty('--cardShadow', '20rem 20rem 60rem #00000011, -20rem -20rem 60rem #ffffff55');
  docEl.style.setProperty('--mobileCardShadow', '0 1rem 6rem 0 rgba(0, 0, 0, 0.05)');
  docEl.style.setProperty('--listBorder', '1rem solid rgba(0, 0, 0, 0.25)');
  docEl.style.setProperty('--topMenuBorder', '1rem solid rgba(0, 0, 0, 0.25)');
  docEl.style.setProperty('--stdBorder', '1rem solid rgba(0, 0, 0, 0.1)');
  docEl.style.setProperty('--lightThemeOnlyBorder', '1rem solid rgba(0, 0, 0, 0.1)');
  docEl.style.setProperty('--faintBorder', '1rem solid rgba(0, 0, 0, 0.1)');
  docEl.style.setProperty('--menuBorder', '2rem solid rgba(0, 0, 0, 0.1)');
  docEl.style.setProperty('--accordionBorder', '1rem solid rgba(0, 0, 0, 0.05)');
  docEl.style.setProperty('--galleryShadow', '0 2rem 4rem rgba(0, 0, 0, 0.4)');
  docEl.style.setProperty('--galleryShadowHover', '0 3rem 6rem rgba(0, 0, 0, 0.5)');
  docEl.style.setProperty('--darkShadow', '0 4rem 8rem rgba(0, 0, 0, 0.4)');
  docEl.style.setProperty('--darkerShadow', '0 5rem 10rem rgba(0, 0, 0, 0.7)');
  docEl.style.setProperty('--accentShadow', '0 0 5rem rgba(47, 140, 226, 0.7)');
  docEl.style.setProperty('--strongAccentShadow', '0 0 8rem rgba(47, 140, 226, 0.7)');
  docEl.style.setProperty('--wordleWarm', '#f1ce35');
  docEl.style.setProperty('--wordleHot', '#23d623');
  docEl.style.setProperty('--wordleUsed', 'rgba(50, 50, 50, 0.5)');
  docEl.style.setProperty('--darkOnlyMargin', '0rem');

  if (
    metaThemeIsLightOrNone ||
    (!hasBackgroundImage && (lightBackgroundColors.includes(backgroundColor?.toLowerCase()) || backgroundColor?.toLowerCase().includes(`linear-gradient(to right, ${defaultThemeBackgroundColor},`)))
  ) {
    // Is light theme
    store.commit('isLightTheme', true);
    contentBackgroundColor = `rgba(255, 255, 255, ${alpha3})`;

    docEl.style.setProperty('--colorScheme', 'light');
    docEl.style.setProperty('--stdFontColor', '#333');
    docEl.style.setProperty('--scrollBarColor', 'rgba(0, 0, 0, 0.6)');
    docEl.style.setProperty('--contentBackgroundColor', contentBackgroundColor);
    docEl.style.setProperty('--inputBackgroundColor', 'rgba(255, 255, 255, 0.9)');
    docEl.style.setProperty('--strongBackgroundColor', `rgba(255, 255, 255, ${alpha3})`);
    docEl.style.setProperty('--cardShadow', '20rem 20rem 60rem #00000011, -20rem -20rem 60rem #ffffff55');
    docEl.style.setProperty('--listBorder', `1rem solid rgba(0, 0, 0, ${alpha1})`);
    docEl.style.setProperty('--topMenuBorder', `1rem solid rgba(0, 0, 0, ${alpha1})`);
    docEl.style.setProperty('--stdBorder', `1rem solid rgba(0, 0, 0, ${alpha2})`);
    docEl.style.setProperty('--lightThemeOnlyBorder', `1rem solid rgba(0, 0, 0, ${alpha1})`);
    docEl.style.setProperty('--faintBorder', `1rem solid rgba(0, 0, 0, ${alpha1})`);
    docEl.style.setProperty('--menuBorder', `2rem solid rgba(0, 0, 0, ${alpha2})`);
    docEl.style.setProperty('--accordionBorder', `1rem solid rgba(0, 0, 0, ${alpha2})`);
    docEl.style.setProperty('--galleryShadow', '0 3rem 5rem rgba(0, 0, 0, 0.3)');
    docEl.style.setProperty('--galleryShadowHover', '0 4rem 8rem rgba(0, 0, 0, 0.4)');
    docEl.style.setProperty('--darkShadow', '0 3rem 5rem rgba(0, 0, 0, 0.25)');
    docEl.style.setProperty('--darkerShadow', '0 4rem 8rem rgba(0, 0, 0, 0.375)');
    docEl.style.setProperty('--accentShadow', `0 0 5rem rgba(${hexToRgb(accentColor)} 1)`);
    docEl.style.setProperty('--strongAccentShadow', `0 0 8rem rgba(${hexToRgb(accentColor)} 1)`);
    docEl.style.setProperty('--wordleWarm', `#f1ce35`);
    docEl.style.setProperty('--wordleHot', `#23d623`);
    docEl.style.setProperty('--darkOnlyMargin', `0rem`);
    if (hasBackgroundImage) {
      contentBackgroundColor = 'rgba(255, 255, 255, 0.85)';
      docEl.style.setProperty('--scrollBarColor', 'rgba(0, 0, 0, 0.6)');
      docEl.style.setProperty('--contentBackgroundColor', contentBackgroundColor);
      docEl.style.setProperty('--backgroundImage', backgroundImage);
      docEl.style.setProperty('--backgroundColor', '#ededf7');
    } else {
      docEl.style.setProperty('--backgroundColor', backgroundColor);
    }
  } else {
    // Is dark theme
    store.commit('isLightTheme', false);
    contentBackgroundColor = 'rgba(255, 255, 255, 0.06)';

    docEl.style.setProperty('--colorScheme', 'dark');
    docEl.style.setProperty('--stdFontColor', '#fff');
    docEl.style.setProperty('--scrollBarColor', 'rgba(255, 255, 255, 0.3)');
    docEl.style.setProperty('--contentBackgroundColor', contentBackgroundColor);
    docEl.style.setProperty('--inputBackgroundColor', 'rgba(255, 255, 255, 0.15)');
    docEl.style.setProperty('--strongBackgroundColor', 'rgba(255, 255, 255, 0.21)'); // 0.06 + 0.15
    docEl.style.setProperty('--backgroundImageOpacity', '0.1');
    docEl.style.setProperty('--cardShadow', '0 20rem 60rem #00000020');
    docEl.style.setProperty('--listBorder', `1rem solid rgba(255, 255, 255, ${alpha1})`);
    docEl.style.setProperty('--topMenuBorder', `none`);
    docEl.style.setProperty('--stdBorder', `1rem solid rgba(255, 255, 255, ${alpha2})`);
    docEl.style.setProperty('--lightThemeOnlyBorder', '1rem solid transparent');
    docEl.style.setProperty('--faintBorder', `1rem solid rgba(255, 255, 255, ${alpha1})`);
    docEl.style.setProperty('--menuBorder', `none`);
    docEl.style.setProperty('--accordionBorder', `1rem solid rgba(0, 0, 0, ${alpha2})`);
    docEl.style.setProperty('--galleryShadow', '0 3rem 5rem rgba(0, 0, 0, 0.5)');
    docEl.style.setProperty('--galleryShadowHover', '0 4rem 8rem rgba(0, 0, 0, 0.6)');
    docEl.style.setProperty('--darkShadow', '0 3rem 5rem rgba(0, 0, 0, 0.3)');
    docEl.style.setProperty('--darkerShadow', '0 4rem 8rem rgba(0, 0, 0, 0.4)');
    docEl.style.setProperty('--accentShadow', `0 0 5rem rgba(${hexToRgb(accentColor)} 1)`);
    docEl.style.setProperty('--strongAccentShadow', `0 0 8rem rgba(${hexToRgb(accentColor)} 1)`);
    docEl.style.setProperty('--wordleWarm', `#c48d00`);
    docEl.style.setProperty('--wordleHot', `#0e6d0e`);
    docEl.style.setProperty('--darkOnlyMargin', `1rem`);
    if (hasBackgroundImage) {
      docEl.style.setProperty('--scrollBarColor', 'rgba(0, 0, 0, 0.6)');
      docEl.style.setProperty('--contentBackgroundColor', contentBackgroundColor);
      docEl.style.setProperty('--backgroundImage', backgroundImage);
      docEl.style.setProperty('--backgroundColor', '#000');
    } else {
      docEl.style.setProperty('--backgroundColor', backgroundColor);
    }
  }
  if (isFlatCardStyle) {
    docEl.style.setProperty('--cardShadow', 'none');
    docEl.style.setProperty('--mobileCardShadow', 'none');
    docEl.style.setProperty('--contentBackgroundColor', 'transparent');
  } else if (isSharpCardStyle) {
    docEl.style.setProperty('--cardRadius', '2rem');
    docEl.style.setProperty('--mobileCardRadius', '2rem');
  } else {
    // Reset back to defaults in case we're auto sitching.
    docEl.style.setProperty('--cardRadius', '50rem');
    docEl.style.setProperty('--mobileCardRadius', '20rem');
    docEl.style.setProperty('--mobileCardShadow', '0 1rem 6rem 0 rgba(0, 0, 0, 0.05)');
  }
  docEl.style.setProperty('--logoBackgroundImage', logoBackgroundImage);
  if (logoBackgroundImage !== 'none') {
    docEl.style.setProperty('--displayLogo', 'block');
  }
  docEl.style.setProperty('--accentColor', accentColor);
  docEl.style.setProperty('--primaryFontName', primaryFontName);
  docEl.style.setProperty('--headerFontName', headerFontName);
  docEl.style.setProperty('--headerFontTransform', headerFontTransform);
  docEl.style.setProperty('--primaryFontItalics', primaryFontItalics === 1 ? 'italic' : 'initial');
  docEl.style.setProperty('--headerFontItalics', headerFontItalics === 1 ? 'italic' : 'initial');
  docEl.style.setProperty('--primaryFontScale', primaryFontScale.toString());
  docEl.style.setProperty('--headerFontScale', headerFontScale.toString());
  docEl.style.setProperty('--primaryFontWeight', primaryFontWeight.toString());
  docEl.style.setProperty('--headerFontWeight', headerFontWeight.toString());

  if (appendLinkEl) {
    docHead.appendChild(linkEl);
  }
  docHead.appendChild(metaEl);
};
