// Important: These labels are stored in the database verbatim, don't change them!
export enum eModeType {
  urlRedirect = 'urlRedirect',
  group = 'group',
  site = 'site',
  profile = 'profile',
  article = 'article',
  files = 'files',
  personalPayment = 'personalPayment',
  businessPayment = 'businessPayment',
  wifi = 'wifi',
  linkList = 'linkList',
  gallery = 'gallery',
  shop = 'shop',
  html = 'html',
  events = 'events',
  booking = 'booking',
  customForm = 'customForm',
  blog = 'blog',
  accordion = 'accordion',
  chat = 'chat',
  returnTo = 'returnTo',
  chess = 'chess',
  wordle = 'wordle',
  trivia = 'trivia',
  card = 'card',
  broadcast = 'broadcast',
}

export enum eCardStyle {
  flat = 'flat',
  sharp = 'sharp',
  round = 'round',
}

export enum eSnackBarTypes {
  error = 'error',
  warning = 'warning',
  notice = 'notice',
  success = 'success',
}

export enum eWeekdays {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum eCustomFieldTypes {
  email = 'email',
  phoneNumber = 'phoneNumber',
  url = 'url',
  shortText = 'shortText',
  longText = 'longText',
  number = 'number',
  date = 'date',
  time = 'time',
  dateTime = 'dateTime',
  checkbox = 'checkbox',
  outOf5 = 'outOf5',
  multipleChoice = 'multipleChoice',
  fileUpload = 'fileUpload',
  signature = 'signature',
  address = 'address',
  nonInputTextDescription = 'nonInputTextDescription',
}

export enum eChargeStatuses {
  completed = 'completed',
  cancelled = 'cancelled',
  authorized = 'authorized',
}

export enum eOrderStatuses {
  noStatus = 'noStatus',
  onHold = 'onHold',
  accepted = 'accepted',
  rejected = 'rejected',
  completed = 'completed',
  // Delivery specific
  dispatched = 'dispatched',
  delivered = 'delivered',
  // Take out specific
  readyForPickUp = 'readyForPickUp',
  pickedUp = 'pickedUp',
}

export enum eTranslationLocaleCode {
  en = 'en',
  af = 'af',
  sq = 'sq',
  am = 'am',
  ar = 'ar',
  hy = 'hy',
  az = 'az',
  eu = 'eu',
  be = 'be',
  bn = 'bn',
  bs = 'bs',
  bg = 'bg',
  ca = 'ca',
  zh = 'zh',
  co = 'co',
  hr = 'hr',
  cs = 'cs',
  da = 'da',
  nl = 'nl',
  eo = 'eo',
  et = 'et',
  fi = 'fi',
  fr = 'fr',
  fy = 'fy',
  gl = 'gl',
  ka = 'ka',
  de = 'de',
  el = 'el',
  gu = 'gu',
  ht = 'ht',
  ha = 'ha',
  he = 'he',
  hi = 'hi',
  hu = 'hu',
  is = 'is',
  ig = 'ig',
  id = 'id',
  ga = 'ga',
  it = 'it',
  ja = 'ja',
  jv = 'jv',
  kn = 'kn',
  kk = 'kk',
  km = 'km',
  rw = 'rw',
  ko = 'ko',
  ku = 'ku',
  ky = 'ky',
  lo = 'lo',
  la = 'la',
  lv = 'lv',
  lt = 'lt',
  lb = 'lb',
  mk = 'mk',
  mg = 'mg',
  ms = 'ms',
  ml = 'ml',
  mt = 'mt',
  mi = 'mi',
  mr = 'mr',
  mn = 'mn',
  my = 'my',
  ne = 'ne',
  no = 'no',
  ny = 'ny',
  or = 'or',
  ps = 'ps',
  fa = 'fa',
  pl = 'pl',
  pt = 'pt',
  pa = 'pa',
  ro = 'ro',
  ru = 'ru',
  sm = 'sm',
  gd = 'gd',
  sr = 'sr',
  st = 'st',
  sn = 'sn',
  sd = 'sd',
  si = 'si',
  sk = 'sk',
  sl = 'sl',
  so = 'so',
  es = 'es',
  su = 'su',
  sw = 'sw',
  sv = 'sv',
  tl = 'tl',
  tg = 'tg',
  ta = 'ta',
  tt = 'tt',
  te = 'te',
  th = 'th',
  tr = 'tr',
  tk = 'tk',
  uk = 'uk',
  ur = 'ur',
  ug = 'ug',
  uz = 'uz',
  vi = 'vi',
  cy = 'cy',
  xh = 'xh',
  yi = 'yi',
  yo = 'yo',
  zu = 'zu',
  notSet = 'notSet',
}

export enum eMenuIconTypes {
  none = 'none',
  standard = 'standard',
  emoji = 'emoji',
}

export enum eThemes {
  dark = 'dark',
  light = 'light',
}

export enum eDeliveryTypes {
  local = 'local',
  shipping = 'shipping',
}

export enum eModeTaxRateType {
  locationBased = 'locationBased',
  default = 'default',
}

export enum eTaxTypes {
  userSpecified = 'userSpecified',
  stripe = 'stripe',
}

export enum eInteractionDeviceTypes {
  // Name format is: ${Card Color}${Print Color (optional)}${Device Type}
  // Legacy
  blackSitchCardV2 = 'blackSitchCardV2',
  // Standard
  blackSitchCard = 'blackSitchCard',
  blackSilverSitchCard = 'blackSilverSitchCard',
  blackGoldSitchCard = 'blackGoldSitchCard',
  whiteSitchCard = 'whiteSitchCard',
  whiteSilverSitchCard = 'whiteSilverSitchCard',
  whiteGoldSitchCard = 'whiteGoldSitchCard',
  // Chat
  blackChatSitchCard = 'blackChatSitchCard',
  blackSilverChatSitchCard = 'blackSilverChatSitchCard',
  blackGoldChatSitchCard = 'blackGoldChatSitchCard',
  whiteChatSitchCard = 'whiteChatSitchCard',
  whiteSilverChatSitchCard = 'whiteSilverChatSitchCard',
  whiteGoldChatSitchCard = 'whiteGoldChatSitchCard',
  // Custom Logo Cards
  whiteCustomLogoSitchCard = 'whiteCustomLogoSitchCard',
  whiteSilverCustomLogoSitchCard = 'whiteSilverCustomLogoSitchCard',
  whiteGoldCustomLogoSitchCard = 'whiteGoldCustomLogoSitchCard',
  blackWhiteCustomLogoSitchCard = 'blackWhiteCustomLogoSitchCard',
  blackSilverCustomLogoSitchCard = 'blackSilverCustomLogoSitchCard',
  blackGoldCustomLogoSitchCard = 'blackGoldCustomLogoSitchCard',
  // Non Cards
  blackSitchBand = 'blackSitchBand',
  blackSitchDot = 'blackSitchDot',
  blackSitchBumper = 'blackSitchBumper',
  rectangular11mmx26mmStickerPackSize5 = 'rectangular11mmx26mmStickerPackSize5',
  rectangular11mmx26mmStickerSingle = 'rectangular11mmx26mmStickerSingle',
  square16mmx16mmStickerPackSize5 = 'square16mmx16mmStickerPackSize5',
  // Clothes
  blackUnisexShirt = 'blackUnisexShirt',
  blackWomensShirt = 'blackWomensShirt',
  // Partners
  tapeeo = 'tapeeo',
  gust = 'gust',
  umbCard = 'umbCard',
  // Digital
  smartLink = 'smartLink',
}

export enum eInteractionMethods {
  inAppQr = 0,
  cardNfc = 1,
  cardQr = 2,
  bandNfc = 3,
  dotNfc = 4,
  stickerNfc = 5,
  link = 6,
  bumper = 7,
}

export enum eGameTypes {
  chess = 'chess',
  wordle = 'wordle',
  trivia = 'trivia',
}

export enum eSitchCardModifiers {
  whitePrintOnBlack = 'White Print on Black',
  goldPrintOnBlack = 'Gold Print on Black',
  silverPrintOnBlack = 'Silver Print on Black',
  blackPrintOnWhite = 'Black Print on White',
  goldPrintOnWhite = 'Gold Print on White',
  silverPrintOnWhite = 'Silver Print on White',
  colorPrintOnWhite = 'Color Print on White',
}

export enum eSitchProductNames {
  sitchCard = 'Sitch Card',
  customLogoSitchCard = 'Custom Logo Sitch Card',
  chatSitchCard = 'Chat Sitch Card',
}

export enum eMetaTheme {
  light = 'light',
  dark = 'dark',
}