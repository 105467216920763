
import Vue from 'vue';

export default Vue.extend({
  props: {
    image: {
      type: String,
      default: '',
    },
  },
  data(): {
    showModal: boolean;
  } {
    return {
      showModal: false,
    };
  },
});
