import { eModeType } from '@/enums';
import { store } from '@/store';
import { standardApiFetch } from './api-utils';
import { currFirestore, getParameterByName, isProductionEnv } from './initialization-util';
import { showError, showSuccess } from './notice-utils';
import { collection, doc } from 'firebase/firestore';
import imageCompression from 'browser-image-compression';

export const getFileIcon = (mimeType: string): string => {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  const iconClasses: any = {
    // Media
    image: 'file-image',
    audio: 'file-audio',
    video: 'file-video',
    // Documents
    'application/pdf': 'file-pdf',
    'application/msword': 'file-word',
    'application/vnd.ms-word': 'file-word',
    'application/vnd.oasis.opendocument.text': 'file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml': 'file-word',
    'application/vnd.ms-excel': 'file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
    'application/vnd.ms-powerpoint': 'file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml': 'file-powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
    'text/plain': 'file-alt',
    'text/x-java-source,java': 'file-code',
    'application/javascript': 'file-code',
    'application/json': 'file-code',
    // Archives
    'application/gzip': 'file-archive',
    'application/zip': 'file-archive',
  };

  for (const key in iconClasses) {
    if (iconClasses[key]) {
      if (mimeType.search(key) === 0) {
        // Found it
        return iconClasses[key];
      }
    }
  }
  return 'file';
};

export const base64DataURLtoFile = (dataurl = '', fileName = '') => {
  if (!dataurl) {
    showError('No base 64 data to convert to a file.');
    return;
  }
  const arr = dataurl.split(',');
  const mimeType = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = window.atob(arr[1]);  
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const uncompressedFile = new File([u8arr], fileName, { type: mimeType });
  return uncompressedFile;
};

export const shareCurrentPage = () => {
  if (navigator.share) {
    const mode = store.state.mode as AnyMode;
    navigator.share({
      title: 'Sitch',
      text: mode.displayName || mode.name,
      url: window.location.href,
    });
  } else {
    const customPermalinkId: string = getParameterByName('p');
    const shortPermalinkId: string = getParameterByName('s');
    const baseUrl = isProductionEnv ? 'https://sitch.app' : 'https://sitch-client-test.web.app';
    if (customPermalinkId) {
      copyToClipboard(`${baseUrl}/${customPermalinkId}`);
    } else if (shortPermalinkId) {
      copyToClipboard(`${baseUrl}/s/${shortPermalinkId}`);
    } else {
      copyToClipboard(window.location.href);
    }
  }
};

export const generateId = () => {
  const newId = doc(collection(currFirestore, 'This is just to generate IDs')).id;
  return newId;  
};

export const reinitializeState = (data: Record<string, any>, initialState: () => any) => {
  const initialStateCopy: any = initialState();
  for (const prop of Object.keys(initialStateCopy)) {
    data[prop] = initialStateCopy[prop];
  }
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  showSuccess('Copied to clipboard');
};

export const mobileCheck = () => {
  let check = false;
  const typedWindow: any = window;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || typedWindow.opera);
  return check;
};

export const isEmptyObject = (obj: any): boolean => {
  return obj && Object.entries(obj).length === 0 && obj.constructor === Object;
};

export const getSubscriptionData = (publicUserModeGateway: PublicUserModeGateway) => {
  if (publicUserModeGateway.hasPermanentPremium) {
    store.commit('isPremiumActive', true);
    return;
  }
  const premiumSubscriptionId = publicUserModeGateway.premiumSubscriptionId;
  if (premiumSubscriptionId) {
    standardApiFetch('get-stripe-subscription', {
      premiumSubscriptionId,
    }).then((response) => {
      store.commit('isPremiumActive', response.successfulResponse.subscription?.status === 'active');
    });
  }
};

// Runs the passed in function once the time in ms has completed, but resets the timer if delay is called again.
export const delay = (func: () => void, ms: number) => {
  const doc: any = document as any;
  (() => {
    if (doc.genericDelayTimer) {
      clearTimeout(doc.genericDelayTimer);
    }
    doc.genericDelayTimer = setTimeout(func, ms);
  })();
};

export const nonDebugLog = (...args: any) => {
  console.log(...args);
};

export const highlightElement = (element: any) => {
  if (!element) {
    return;
  }
  element.classList.add('highlight-area');
  setTimeout(() => {
    element.classList.remove('highlight-area');
  }, 1000);
};

export const scrollToTop = () => {
  (document as Document).getElementById('app')?.scrollTo(0, 0);
};

export const formatNumber = (phone: string | number): string => {
  const cleaned = ('' + phone).replace(/[^0-9.+]/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phone.toString();
};

export const addHttp = (url: string, https = false): string => {
  const urlSchemeRegEx = /(^\w+:|^)\/\//;
  if (!url) {
    return '';
  }
  if (urlSchemeRegEx.test(url)) {
    return url;
  }
  // If there is no scheme we just prepend http(s)://.
  if (https) {
    url = 'https://' + url;
  } else {
    url = 'http://' + url;
  }

  return url;
};

export const getPayServiceLink = (mode: PersonalPaymentsMode, url: string) => {
  return addHttp(`${url}/${parseFloat(((store.state.queryStringAmount || mode.amount) / 100 || 0).toString(10)).toFixed(2)}`);
};

export const getVenmoLink = (mode: PersonalPaymentsMode) => {
  const amountString = `amount=${parseFloat(((store.state.queryStringAmount || mode.amount) / 100 || 0).toString(10)).toFixed(2)}`;
  if (mode.venmoUrl.includes('?')) {
    return addHttp(`${mode.venmoUrl}&${amountString}`);
  }
  return addHttp(`${mode.venmoUrl}?${amountString}`);
};

export const shortenLabel = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substr(0, maxLength) + '...';
  }
  return str;
};

export const getModeIcon = (type: eModeType) => {
  switch (type) {
    case eModeType.profile:
      return 'address-card';
    case eModeType.urlRedirect:
      return 'link';
    case eModeType.article:
      return 'newspaper';
    case eModeType.files:
      return 'archive';
    case eModeType.personalPayment:
      return 'money-bill-wave';
    case eModeType.businessPayment:
      return 'handshake';
    case eModeType.wifi:
      return 'wifi';
    case eModeType.site:
      return 'browser';
    case eModeType.linkList:
      return 'list';
    case eModeType.gallery:
      return 'images';
    case eModeType.group:
      return 'layer-group';
    case eModeType.shop:
      return 'store';
    case eModeType.html:
      return 'code';
    case eModeType.events:
      return 'calendar-alt';
    case eModeType.booking:
      return 'calendar-plus';
    case eModeType.customForm:
      return 'clipboard-list';
    case eModeType.blog:
      return 'megaphone';
    case eModeType.accordion:
      return 'window-maximize';
    case eModeType.returnTo:
      return 'tags';
    case eModeType.chat:
      return 'comments';
    case eModeType.chess:
      return 'chess';
    case eModeType.wordle:
      return 'border-all';
    case eModeType.trivia:
      return 'question-square';
    case eModeType.card:
      return 'book-open';
    default:
      return 'question';
  }
};

export const compressImage = (file: File, maxDimension: number): Promise<CompressedFileObject> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // If image then compress the image.
    imageCompression(file, {
      maxSizeMB: 5, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: maxDimension, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
      maxIteration: 10, // optional, max number of iteration to compress the image (default: 10)
    })
      .then((compressedFile: any) => {
        // We need a bas64 url for preview purposes.
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve({
            compressedFile,
            base64data: base64data as string,
          });
        };
      })
      .catch((error: any) => {
        showError(error.message);
        reject(error);
      });
  });
};