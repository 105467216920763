
import Vue from 'vue';
import { currFirestore, getParameterByName } from '@/util-functions/initialization-util';
import SitchOrderItemBreakdown from '@/components/SitchOrderItemBreakdown.vue';
import { format } from 'date-fns';
import { showError } from '@/util-functions/notice-utils';
import { doc, getDoc } from 'firebase/firestore';

export default Vue.extend({
  components: {
    SitchOrderItemBreakdown,
  },
  data(): {
    paymentIntentId: string;
    isForInvoice: boolean;
    paymentRecord: GlobalPaymentRecord | null;
    formattedDate: string;
  } {
    return {
      paymentIntentId: getParameterByName('id') || '',
      isForInvoice: Boolean(getParameterByName('invoice')),
      paymentRecord: null,
      formattedDate: '',
    };
  },
  mounted() {
    if (!this.paymentIntentId) {
      return;
    }
    getDoc(doc(currFirestore, 'stripeBusinessPayments', this.paymentIntentId))
      .then((doc) => {
        this.paymentRecord = doc.data() as GlobalPaymentRecord;
        this.formattedDate = format(this.paymentRecord?.dateCreated?.toDate(), 'MMM dd, yyyy h:mm a');
      })
      .catch((error: any) => {
        showError('Could not update order status ' + error?.message);
      });
  },
  methods: {
    printPage() {
      window.setTimeout(() => {
        window.print();
      }, 100); // Painting of the QR code appears to be async but there's no callbacks we can hook into to detect completion, so use a high delay instead.
    },
  },
});
