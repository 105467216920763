
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    blurOnClick: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    isActive: boolean;
  } {
    return {
      isActive: Boolean(this.value),
    };
  },
  watch: {
    value(v) {
      this.isActive = v;
    },
  },
  methods: {
    toggleActive() {
      if (this.blurOnClick) {
        (this.$refs.button as any).blur();
      }
      this.isActive = !this.isActive;
      this.$emit('change', this.isActive);
      this.$emit('input', this.isActive);
    },
  },
});
