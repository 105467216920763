
import Vue, { PropType } from 'vue';
import { eModeType, eMenuIconTypes } from '@/enums';
import { getModeIcon, scrollToTop } from '@/util-functions/misc-utils';
import { modeToWrapId } from '@/util-functions/initialization-util';

export default Vue.extend({
  props: {
    routes: {
      type: Array as PropType<HamburgerRoute[]>,
      required: true,
    },
  },
  data(): {
    isFullNav: boolean;
    eMenuIconTypes: typeof eMenuIconTypes;
  } {
    return {
      isFullNav: false,
      eMenuIconTypes,
    };
  },
  computed: {
    thisIsaWrapper(): boolean {
      const thisMode = this.$store.state.currSite as SiteMode | null;
      const wrapperModeId = this.$store.state.publicUserModeGateway?.wrapperModeId;
      const activeModeId = this.$store.state.publicUserModeGateway?.activeModeId;
      return Boolean(activeModeId && (wrapperModeId === thisMode?.docId || modeToWrapId));
    },
    currSite(): SiteMode | null {
      return this.$store.state.currSite;
    },
    isOnRedirectPage(): boolean {
      return this.$store.state.isOnRedirectPage;
    },
    isPremiumActive(): boolean {
      return this.$store.state.isPremiumActive;
    },
    isLightTheme(): boolean {
      return this.$store.state.isLightTheme;
    },
    mode(): Mode | null {
      return this.$store.state.mode;
    },
    fullNavHeight(): string {
      const navButtonHeight = 55;
      const maxNumberOfModesToShowWithoutScroll = 6;
      const maxNavHeight = navButtonHeight * maxNumberOfModesToShowWithoutScroll;
      let navHeight = this.routes.length * navButtonHeight;
      if (navHeight > maxNavHeight) {
        navHeight = maxNavHeight;
      }
      return -1 * navHeight + 'rem';
    },
  },
  mounted() {
    const el: any = this.$el;
    el.style.bottom = this.fullNavHeight;
    const docEl = document.documentElement as HTMLElement;
    docEl.style.setProperty('--mobile-nav-height', '55rem');
  },
  methods: {
    navButtonClick(route: any) {
      route.onClick();
      this.navToggle();
      scrollToTop();
    },
    getEmojiIcon(index: number, type: eModeType) {
      const emojiIcons = this.currSite?.emojiIcons || [];
      const emoji = emojiIcons[index];

      if (emoji) {
        return emoji;
      }

      switch (type) {
        case eModeType.profile:
          return '🖐';
        case eModeType.urlRedirect:
          return '🌎';
        case eModeType.article:
          return '📰';
        case eModeType.files:
          return '🗄️';
        case eModeType.personalPayment:
          return '💵';
        case eModeType.businessPayment:
          return '🤝';
        case eModeType.wifi:
          return '📡';
        case eModeType.site:
          return '🌎';
        case eModeType.linkList:
          return '☑️';
        case eModeType.gallery:
          return '🖼️';
        case eModeType.group:
          return '📇';
        case eModeType.shop:
          return '🏪';
        case eModeType.html:
          return '🌎';
        case eModeType.events:
          return '📅';
        case eModeType.booking:
          return '📆';
        case eModeType.customForm:
          return '📋';
        case eModeType.blog:
          return '📝';
        case eModeType.accordion:
          return '📖';
        case eModeType.returnTo:
          return '↩';
        case eModeType.chat:
          return '💬';
        case eModeType.chess:
          return '♟';
        case eModeType.wordle:
          return '🔠';
        case eModeType.trivia:
          return '🤔';
        case eModeType.card:
          return '🃏';
        default:
          return '❓';
      }
    },
    getModeIcon,
    navToggle() {
      this.isFullNav = !this.isFullNav;
      const el: any = this.$el;
      if (this.isFullNav) {
        el.style.bottom = 0;
      } else {
        el.style.bottom = this.fullNavHeight;
      }
      el.scrollTop = 0;
    },
  },
  watch: {
    $route() {
      this.isFullNav = true;
      this.navToggle();
    },
  },
});
