import Vue from 'vue';
import VueRouter from 'vue-router';
import LinkActions from '@/views/LinkActions.vue';
import TransactionSummary from '@/views/TransactionSummary.vue';
import { hideLoading, showLoading } from './util-functions/loading-utils';

Vue.use(VueRouter);

// Keep these uppercase so they don't conflict with permalinks

export const routerModes = [
  'Profile',
  'PersonalPayment',
  'BusinessPayment',
  'Article',
  'Files',
  'LinkList',
  'Gallery',
  'Group',
  'CustomForm',
  'Shop',
  'Wifi',
  'Html',
  'Redirect',
  'Booking',
  'Events',
  'UrlRedirect',
  'Blog',
  'Accordion',
  'Chat',
  'ReturnTo',
  'Chess',
  'Wordle',
  'Trivia',
  'Card',
]
const preloadedRoutes = [
  {
    path: `/LinkActions`,
    component: LinkActions,
  },
  {
    path: `/TransactionSummary`,
    component: TransactionSummary,
  },
];

export const allRoutes = [...routerModes, ...preloadedRoutes.map(route => route.path.substring(1))];

const routes = [...preloadedRoutes, ...routerModes.map((modeName) => {
  return {
    path: `/${modeName}`,
    component: () => import(`@/views/${modeName}.vue`),
  };
})];

const routerBase = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

routerBase.beforeEach((to, from, next) => {
  hideLoading();
  showLoading();
  next();
});

routerBase.afterEach(() => {
  hideLoading(true);
});

export const router = routerBase;
