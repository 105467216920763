
import CustomForm from '@/views/CustomForm.vue';
import countries from '@/countries';
import Vue, { PropType } from 'vue';
import { eventBus, getStripePublicKey, t, isProductionEnv, currFirestore } from '@/util-functions/initialization-util';
import { loadStripe, PaymentIntent, StripeCardElement, Stripe, StripeCardElementChangeEvent, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { demoAccountUserId, endpoints } from '@/constants';
import { eValidationRules, FormValidationMapping, isFormValid } from '@/validation';
import { eModeTaxRateType, eModeType } from '@/enums';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/store';
import { formatCurrency, getTaxRateForMode } from '@/util-functions/money-utils';
import { showError, showNotice } from '@/util-functions/notice-utils';
import { hideLoading, showLoading } from '@/util-functions/loading-utils';
import { standardApiFetch } from '@/util-functions/api-utils';
import { analyzeClick } from '@/util-functions/analytics-utils';
import { nonDebugLog, scrollToTop } from '@/util-functions/misc-utils';
import { collection, doc, getDoc } from 'firebase/firestore';

type OversoldData = {
  [idOfSoldOutThing: string]: {
    name: string;
    stockLeft: number;
    totalOrdered: number;
  };
};

export default Vue.extend({
  components: { CustomForm },
  props: {
    baseAmount: {
      type: Number,
      default: 0,
    },
    totalTaxAmountAccountingForUniqueTaxRates: {
      type: Number,
      default: null,
    },
    orderBreakdown: {
      type: Array as PropType<OrderItem[]>,
      default: () => [],
    },
    onPurchaseCompelete: {
      type: Function,
      default: () => undefined,
    },
    deliveryAddress: {
      type: Object as PropType<DetailedAddress | null>,
      default: null,
    },
    mode: {
      type: Object as PropType<StripeEnabledMode>,
      required: true,
    },
    discountPercentage: {
      type: Number,
      default: 0,
    },
    discountAmount: {
      type: Number,
      default: 0,
    },
    discountOnTaxAmount: {
      type: Number,
      default: 0,
    },
    promoCode: {
      type: String,
      default: '',
    },
    propsEmail: {
      type: String,
      default: '',
    },
  },
  data(): {
    showDigitalWalletButton: boolean;
    stripeClient: Stripe | null;
    paymentIntent: PaymentIntent | null;
    cardElementInstance: StripeCardElement | null;
    tipMultiplier: number;
    otherTipMultiplier: number;
    isCardValid: boolean;
    cardPaymentAttemptedSinceLastCardValidation: boolean;
    readyForPayment: boolean;
    customerEmail: string;
    customerName: string;
    successfulPaymentIntent: PaymentIntent | null;
    totalAmount: number;
    tipAmount: number;
    taxAmount: number;
    gettingPaymentIntent: boolean;
    cannotCompletePaymentMessage: string;
    submittingPayment: boolean;
    preSubmissionFormMode: CustomFormMode | null;
    postSubmissionFormMode: CustomFormMode | null;
    cannotSubmit: boolean;
    applePayAvailable: boolean;
    customFieldData: {
      [id: string]: CustomFieldData;
    };
    paymentRequestMethodEvent: PaymentRequestPaymentMethodEvent | null;
    isProductionEnv: boolean;
    demoPayClicked: boolean;
  } {
    const applePayAvailable = Boolean((window as any).ApplePaySession);

    return {
      showDigitalWalletButton: false,
      stripeClient: null,
      paymentIntent: null,
      cardElementInstance: null,
      tipMultiplier: 0.15,
      otherTipMultiplier: 0,
      isCardValid: false,
      cardPaymentAttemptedSinceLastCardValidation: false,
      readyForPayment: false,
      customerEmail: this.propsEmail || getLocalStorageItem('email') || '',
      customerName: getLocalStorageItem('name') || this.deliveryAddress?.name || '',
      successfulPaymentIntent: null,
      totalAmount: 0,
      tipAmount: 0,
      taxAmount: 0,
      gettingPaymentIntent: false,
      cannotCompletePaymentMessage: '',
      submittingPayment: false,
      preSubmissionFormMode: null,
      postSubmissionFormMode: null,
      cannotSubmit: false,
      customFieldData: {},
      applePayAvailable,
      paymentRequestMethodEvent: null,
      isProductionEnv,
      demoPayClicked: false,
    };
  },
  computed: {
    isEmbedded(): boolean {
      return this.$store.state.isEmbedded;
    },
    showPayment(): boolean {
      return Boolean(this.stripeAccount && this.customerEmail && (this.customerName || !this.mode.requireName));
    },
    stripeAccount(): StripeAccount | null {
      return this.$store.state.publicUserModeGateway?.stripeAccountsMap?.[this.mode.stripeAccountId] || null;
    },
    htmlFormattedBaseAmount(): string {
      return formatCurrency(this.baseAmount, this.mode.currency, false, true);
    },
    htmlFormattedDiscountAmount(): string {
      return '-' + formatCurrency(this.discountAmount, this.mode.currency, false, true);
    },
    htmlFormattedTaxAmount(): string {
      return formatCurrency(this.taxAmount, this.mode.currency, false, true);
    },
    htmlFormattedTipAmount(): string {
      return formatCurrency(this.tipAmount, this.mode.currency, false, true);
    },
    htmlFormattedTotalAmount(): string {
      return formatCurrency(this.totalAmount, this.mode.currency, false, true);
    },
    htmlFormattedFlatDeliveryFee(): string {
      return formatCurrency(this.calculatedFlatDeliveryFee, this.mode.currency, false, true);
    },
    calculatedFlatDeliveryFee(): number {
      const shopMode = this.mode as ShopMode;
      if (shopMode.freeShippingCountries && shopMode.freeShippingCountries.length) {
        const deliveryAddressCountry = this.deliveryAddress?.address?.country;
        const deliveryAddressCountryObject = countries.find((c) => c.text === deliveryAddressCountry);
        if (deliveryAddressCountryObject && shopMode.freeShippingCountries.includes(deliveryAddressCountryObject.value)) {
          return 0;
        }
      }
      return this.mode.flatDeliveryFee || 0;
    },
    taxRate(): { taxRate: number; type: eModeTaxRateType } {
      return getTaxRateForMode(this.mode, this.deliveryAddress);
    },
    variableTaxRatesOnCertainItems(): boolean {
      return Boolean(this.totalTaxAmountAccountingForUniqueTaxRates || this.mode.hasDeliveryTaxRate);
    },
    isDemoAccount() {
      return this.$store.state.userId === demoAccountUserId;
    },
  },
  watch: {
    customerEmail() {
      setLocalStorageItem('email', this.customerEmail);
      this.readyForPayment = false;
    },
    customerName() {
      setLocalStorageItem('name', this.customerName);
      this.readyForPayment = false;
    },
    stripeAccount() {
      this.onPageLoad();
    },
    otherTipMultiplier() {
      this.setOthertip();
    },
    tipMultiplier() {
      this.recalculateAmounts();
    },
    baseAmount() {
      this.recalculateAmounts();
    },
    totalTaxAmountAccountingForUniqueTaxRates() {
      this.recalculateAmounts();
    },
    discountPercentage() {
      this.recalculateAmounts();
    },
    discountAmount() {
      this.recalculateAmounts();
    },
    discountOnTaxAmount() {
      this.recalculateAmounts();
    },
  },
  mounted() {
    this.onPageLoad();
    eventBus.$on('moneySymbolsUpdated', () => {
      this.$forceUpdate();
    });
  },
  methods: {
    onPageLoad() {
      if (this.stripeAccount) {
        loadStripe(getStripePublicKey(), {
          stripeAccount: this.stripeAccount.stripeUserId,
        })
          .then((newStripe) => {
            this.stripeClient = newStripe;
            // If Business Payment mode or Shop mode with pre checkout, and we already have name and email then just go straight to checkout.
            if (this.stageOneValidationsPassed(true)) {
              this.createPaymentIntent();
            }
          })
          .catch((error: any) => {
            showError(`Stripe failed to load. You may need to add an exception to your adblocker or firewall. ${error?.message || error}`, true, error);
          });
      } else {
        nonDebugLog('Stripe account not yet loaded.');
      }
      this.recalculateAmounts();

      const mode: SubmissionMode = (this as any).mode;
      const modesCollection = collection(currFirestore, 'publicUserModeGateways', this.$store.state.userId, 'modes');

      if (mode.preSubmissionCustomFormModeId && !this.preSubmissionFormMode) {
        getDoc(doc(currFirestore, modesCollection.path, mode.preSubmissionCustomFormModeId))
          .then((docSnap) => {
            if (docSnap.exists()) {
              this.preSubmissionFormMode = docSnap.data() as CustomFormMode;
            }
          })
          .catch((error) => {
            showError(`Could not load pre-submission form. Try refreshing. ${error?.message || error}`, true, error);
            this.cannotSubmit = true;
          });
      }

      if (mode.postSubmissionCustomFormModeId && !this.postSubmissionFormMode) {
        getDoc(doc(currFirestore, modesCollection.path, mode.postSubmissionCustomFormModeId))
          .then((docSnap) => {
            if (docSnap.exists()) {
              this.postSubmissionFormMode = docSnap.data() as CustomFormMode;
            }
          })
          .catch((error) => {
            showError(`Could not load post-submission form. Try refreshing. ${error?.message || error}`, true, error);
            this.cannotSubmit = true;
          });
      }
    },
    onDemoPay() {
      showLoading();
      setTimeout(() => {
        hideLoading();
        this.demoPayClicked = true;
        this.onPaymentConfirmed();
      }, 500);
    },
    recalculateAmounts() {
      this.paymentIntent = null;
      this.readyForPayment = false;
      let newAmount = this.baseAmount - this.discountAmount;

      let locationBasedTaxRate: number | null = null;
      let defaultTaxRateAsALessThanOneFloat: number | null = null;

      switch (this.taxRate.type) {
        case eModeTaxRateType.locationBased:
          locationBasedTaxRate = this.taxRate.taxRate / 100;
          break;
        case eModeTaxRateType.default:
          defaultTaxRateAsALessThanOneFloat = this.taxRate.taxRate / 100;
          break;
      }

      // Priority here is location based flat tax and then default flat tax
      let nonItemSpecificflatSalesTax = (locationBasedTaxRate || defaultTaxRateAsALessThanOneFloat) as number;

      let totalShippingTaxAmount = 0;
      let flatDeliveryFee = 0;

      if (this.mode.requiresDeliveryAddress && this.calculatedFlatDeliveryFee) {
        flatDeliveryFee = this.calculatedFlatDeliveryFee || 0;
        const uniqueDeliveryTaxRateAsALessThanOneFloat = (this.mode.deliveryTaxRate || 0) / 100;
        // Priority for delivery tax is location based flat tax, unique delivery tax, and then default flat tax.
        const taxRateToUseForDelivery =
          locationBasedTaxRate !== null ? locationBasedTaxRate : this.mode.hasDeliveryTaxRate ? uniqueDeliveryTaxRateAsALessThanOneFloat : (defaultTaxRateAsALessThanOneFloat as number);
        totalShippingTaxAmount = Math.round(flatDeliveryFee * taxRateToUseForDelivery);
      }

      const flatSalesTaxAmount = Math.round(newAmount * nonItemSpecificflatSalesTax);

      // Only use this value if it IS a number and not null, and we do not have a location based flat sales tax that would override everything else.
      const ignoreFlatTaxRatesAndUseUniqueItemTaxRates = this.totalTaxAmountAccountingForUniqueTaxRates != null && locationBasedTaxRate === null;

      // Priority here is location based flat tax, then unique item tax rates, and then default flat tax.
      this.taxAmount = totalShippingTaxAmount + (ignoreFlatTaxRatesAndUseUniqueItemTaxRates ? this.totalTaxAmountAccountingForUniqueTaxRates - this.discountOnTaxAmount : flatSalesTaxAmount);

      if (this.mode.tipOptionsEnabled) {
        this.tipAmount = Math.round(newAmount * this.tipMultiplier);
        this.totalAmount = flatDeliveryFee + newAmount + this.taxAmount + this.tipAmount;
      } else {
        this.totalAmount = flatDeliveryFee + newAmount + this.taxAmount;
      }
    },
    openInNewTab() {
      const stringifiedOrderBreakdown = JSON.stringify(this.orderBreakdown);
      setLocalStorageItem('orderBreakdown', stringifiedOrderBreakdown);
      setLocalStorageItem('orderBreakdownShopDocId', this.mode.docId);
      const hasQueryString = window.location.href.includes('?');
      const newPath = hasQueryString ? `/&ob=${encodeURIComponent(stringifiedOrderBreakdown)}` : `/?ob=${encodeURIComponent(stringifiedOrderBreakdown)}`;
      window.open(window.location.href + newPath, '_blank');
    },
    setOthertip() {
      let newVal: number = this.otherTipMultiplier;
      if (newVal > 100) {
        newVal = 100;
      } else if (!newVal || newVal < 0) {
        newVal = 0;
      }
      this.otherTipMultiplier = newVal;
      this.tipMultiplier = newVal / 100;
    },
    setTip(tipMultiplier: number) {
      this.tipMultiplier = tipMultiplier;
    },
    async createPaymentIntent() {
      this.paymentIntent = null;
      this.readyForPayment = false;
      this.cannotCompletePaymentMessage = '';

      if (!this.stageOneValidationsPassed()) {
        return;
      }

      // Keep this in a next tick so that any stripe neccesary UI hidden behind v-ifs have a chance to appear.
      Vue.nextTick(() => {
        const stripeAccount = this.stripeAccount as StripeAccount;

        const getPaymentIntentFunc = (): Promise<PaymentIntent> => {
          return new Promise((intentResolve) => {
            interface CreateIntentRequestPayload {
              platformUserId: string;
              personWhoPaidEmail: string;
              personWhoPaidName: string;
              formattedBaseAmount: string;
              formattedDiscountAmount: string;
              formattedTaxAmount: string;
              formattedTipAmount: string;
              formattedTotalAmount: string;
              formattedDeliveryFee: string;
              baseAmount: number;
              discountAmount: number;
              taxAmount: number;
              tipAmount: number;
              totalAmount: number;
              stripeUserId: string;
              stripeUserCountry: string;
              displayName: string;
              businessEmail: string;
              businessUrl: string;
              businessPhone: string;
              utcOffset: number;
              currency: string;
              deliveryAddress: DetailedAddress | null;
              modeId: string;
              promoCode: string;
              customerFacingBusinessName: string;
              deliveryFee: number;
              locale: string;
              discountPercentage: number;
              hasCustomFields: boolean;
              // The values below are just for validating the values above.
              hasDeliveryTaxRate: boolean;
              orderBreakdown: OrderItem[];
              defaultTaxRate: number;
              taxRateType: string;
              tipMultiplier: number;
              discountOnTaxAmount: number;
              requiresDeliveryAddress: boolean;
              flatDeliveryFee: number;
              deliveryTaxRate: number;
            }

            const createIntentPayload: CreateIntentRequestPayload = {
              platformUserId: this.$store.state.userId,
              modeId: this.mode.docId,
              personWhoPaidEmail: this.customerEmail,
              personWhoPaidName: this.mode.requireName ? this.customerName || '' : '',
              formattedBaseAmount: formatCurrency(this.baseAmount, this.mode.currency),
              formattedDiscountAmount: formatCurrency(this.discountAmount, this.mode.currency),
              formattedTaxAmount: formatCurrency(this.taxAmount, this.mode.currency),
              formattedTipAmount: formatCurrency(this.tipAmount, this.mode.currency),
              formattedTotalAmount: formatCurrency(this.totalAmount, this.mode.currency),
              formattedDeliveryFee: formatCurrency(this.calculatedFlatDeliveryFee, this.mode.currency),
              baseAmount: Number(this.baseAmount),
              discountAmount: Number(this.discountAmount),
              taxAmount: Number(this.taxAmount),
              tipAmount: Number(this.tipAmount),
              totalAmount: Number(this.totalAmount),
              stripeUserId: stripeAccount.stripeUserId,
              stripeUserCountry: stripeAccount.country,
              displayName: stripeAccount.displayName || '',
              businessEmail: stripeAccount.email,
              businessUrl: stripeAccount.url || '',
              businessPhone: stripeAccount.phone || '',
              customerFacingBusinessName: stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName || '',
              utcOffset: -1 * new Date().getTimezoneOffset(),
              currency: this.mode.currency.toLowerCase(),
              deliveryAddress: this.deliveryAddress || null,
              deliveryFee: this.mode.requiresDeliveryAddress ? this.calculatedFlatDeliveryFee || 0 : 0,
              promoCode: this.promoCode,
              locale: navigator?.languages[0] || navigator.language || '',
              discountPercentage: this.discountPercentage,
              hasCustomFields: Boolean(this.mode.preSubmissionCustomFormModeId),
              // The values below are just for validating the values above.
              hasDeliveryTaxRate: Boolean(this.mode.hasDeliveryTaxRate),
              orderBreakdown: this.orderBreakdown,
              defaultTaxRate: this.taxRate.taxRate,
              taxRateType: this.taxRate.type,
              tipMultiplier: this.tipMultiplier,
              discountOnTaxAmount: this.discountOnTaxAmount,
              requiresDeliveryAddress: this.mode.requiresDeliveryAddress || false,
              flatDeliveryFee: this.calculatedFlatDeliveryFee || 0,
              deliveryTaxRate: this.mode.deliveryTaxRate || 0,
            };

            standardApiFetch(endpoints.createStripeIntent, createIntentPayload).then((response) => {
              intentResolve(response.successfulResponse);
              analyzeClick(this.mode as AnyMode, {
                name: 'Payment options clicked',
                id: this.mode.docId + '_paymentOptions',
              });
            });
          });
        };

        showLoading();
        this.gettingPaymentIntent = true;

        getPaymentIntentFunc()
          .then((paymentIntent) => {
            if (!this.stripeClient) {
              nonDebugLog('Stripe client not yet initialized');
              this.gettingPaymentIntent = false;
              hideLoading();
              return;
            }

            this.paymentIntent = paymentIntent;
            const elements = this.stripeClient.elements();
            const paymentRequestOptions = {
              country: stripeAccount.country.toUpperCase(),
              currency: this.mode.currency.toLowerCase(),
              total: {
                label: t.paymentWith.supplant([stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName]),
                amount: this.totalAmount,
              },
              requestPayerName: true,
              requestPayerEmail: true,
            };
            const paymentRequest = this.stripeClient.paymentRequest(paymentRequestOptions);

            const prButton = elements.create('paymentRequestButton', {
              paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: 'dark',
                  type: 'default',
                },
              },
            });

            const onCanMakePaymentResultFailure = () => {
              this.showDigitalWalletButton = false;
              const paymentButton = document.getElementById('payment-request-btn');
              if (paymentButton) {
                console.error('Cannot use payment request button. Hiding the button.');
                paymentButton.style.display = 'none';
              }
            };

            paymentRequest
              .canMakePayment()
              .then((result: any) => {
                if (result) {
                  const paymentRequestElement = document.getElementById('payment-request-btn');
                  if (paymentRequestElement) {
                    paymentRequestElement.innerHTML = '';
                    prButton.mount('#payment-request-btn');
                  } else {
                    nonDebugLog('No element to mount the payment request button to.');
                  }
                  this.showDigitalWalletButton = true;
                } else {
                  console.error('No result. Cannot create payment request button. Result: ', result);
                  onCanMakePaymentResultFailure();
                }
              })
              .catch((error) => {
                console.error(error);
                onCanMakePaymentResultFailure();
              });

            paymentRequest.on('paymentmethod', async (paymentRequestMethodEvent) => {
              // Confirm the PaymentIntent without handling potential next actions (yet).

              this.paymentRequestMethodEvent = paymentRequestMethodEvent;
              this.onSubmitCardOrWalletPayment(true);
            });

            // SETTING UP THE CREDIT CARD ENTRY OPTION.
            // Custom styling can be passed to options when creating an Element.
            // (Note that this demo uses a wider set of styles than the guide below.)

            const textColor: string = this.$store.state.isLightTheme ? '#111111' : '#ffffff';

            const style = {
              base: {
                fontFamily: `Poppins, Helvetica, Arial, sans-serif`,
                color: textColor,
                iconColor: textColor,
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: textColor,
                },
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
              },
            };

            // Create an instance of the card Element.
            this.cardElementInstance = elements.create('card', { style });

            // Add an instance of the card Element into the `card-element` <div>.
            const cardElementElement = document.getElementById('card-element');
            if (cardElementElement) {
              cardElementElement.innerHTML = '';
              this.cardElementInstance.mount('#card-element');
            } else {
              nonDebugLog('No element to mount the card element button to.');
            }

            const checkForCardInputError = (event: StripeCardElementChangeEvent) => {
              if (event.complete) {
                this.isCardValid = true;
                this.cardPaymentAttemptedSinceLastCardValidation = false;
              } else if (event.error) {
                showError(event.error.message);
                this.isCardValid = false;
              } else {
                this.isCardValid = false;
              }
            };

            // Handle real-time validation erors from the card Element.
            this.cardElementInstance.on('change', checkForCardInputError);
            this.isCardValid = false;
            this.gettingPaymentIntent = false;
            this.readyForPayment = true;
            // Do not scroll if this is in a Group Sitch.
            if (!this.isDemoAccount && this.$store.state.mode.type !== eModeType.group) {
              setTimeout(() => {
                document.getElementById('payment-options-section')?.scrollIntoView({ behavior: 'smooth' });
              }, 100);
            }
            hideLoading();
          })
          .catch((error) => {
            showError(`Something went wrong when getting the payment intent. ${error?.message || error}`, true, error);
            this.gettingPaymentIntent = false;
            hideLoading(true);
          });
      });
    },
    /*
    Check to see if the order can be fufilled occurding to remaining stock levels.
    It sums quantity of order items with the same shop item id and compares it to the shop item remaining stock.
    */
    getSoldOutItems(): Promise<OversoldData | null> {
      return new Promise((resolve) => {
        if (!this.orderBreakdown.length) {
          resolve(null);
          return;
        }

        const shopMode = this.mode as ShopMode;
        const shopItemQuantityBeingOrdered: { [shopItemId: string]: number } = {}; // Number is total quantity being ordered of this shop item across all order items.
        const modifierQuantityBeingOrdered: { [modifierId: string]: number } = {}; // Number is total quantity being ordered of this modifier across all order items.
        const soldOutShopItemsMap: { [shopItemId: string]: number } = {}; // Number value is how far over stock the amount ordered for this item is.
        const soldOutModifierMap: { [modifierId: string]: number } = {}; // Number value is how far over stock the amount ordered for this item is.
        const allSoldOutThingsNameToOverSoldAmountMap: OversoldData = {};

        this.orderBreakdown.forEach((orderItem) => {
          const shopItem = shopMode.shopItemList.find((shopItem) => shopItem.id === orderItem.shopItemId);

          if (!shopItem) {
            return;
          }

          if (shopItem.hasStock) {
            shopItemQuantityBeingOrdered[shopItem.id] = shopItemQuantityBeingOrdered[shopItem.id] ? shopItemQuantityBeingOrdered[shopItem.id] + orderItem.quantity : orderItem.quantity;
          }

          const allModifiersInOrderItem = Object.values(orderItem.selectedModifiers).flatMap((modifiers) => {
            return modifiers;
          });

          const allModifiersInShopItem = shopItem.modifierGroups.flatMap((modifierGroup) => {
            return modifierGroup.modifiers;
          });

          allModifiersInOrderItem.forEach((orderItemModifier) => {
            allModifiersInShopItem.forEach((shopItemModifier) => {
              if (shopItemModifier.id === orderItemModifier.modifierId && shopItemModifier.hasStock) {
                modifierQuantityBeingOrdered[shopItemModifier.id] = modifierQuantityBeingOrdered[shopItemModifier.id]
                  ? modifierQuantityBeingOrdered[shopItemModifier.id] + orderItemModifier.quantity
                  : orderItemModifier.quantity;
              }
            });
          });
        });

        const shopItemsThatNeedStockChecks = Object.keys(shopItemQuantityBeingOrdered);
        const modifiersThatNeedStockChecks = Object.keys(modifierQuantityBeingOrdered);

        if (shopItemsThatNeedStockChecks.length > 0 || modifiersThatNeedStockChecks.length > 0) {
          getDoc(doc(currFirestore, 'publicUserModeGateways', this.$store.state.userId, 'modes', shopMode.docId))
            .then((shopModeDocSnap) => {
              if (shopModeDocSnap.exists()) {
                const shopMode = shopModeDocSnap.data() as ShopMode;

                const allModifiers = shopMode.shopItemList.flatMap((shopItem) => {
                  return shopItem.modifierGroups.flatMap((modifierGroup) => {
                    return modifierGroup.modifiers;
                  });
                });

                shopItemsThatNeedStockChecks.forEach((shopItemId) => {
                  const shopItem = shopMode.shopItemList.find((shopItem) => shopItem.id === shopItemId);
                  const totalPurchaseQuantity = shopItemQuantityBeingOrdered[shopItemId];

                  if (!shopItem) {
                    // Order item was delisted while the shop was open in this case so that should count as the thing being out of stock.
                    soldOutShopItemsMap[shopItemId] = 0;
                  } else if (shopItem.hasStock && shopItem.stock < totalPurchaseQuantity) {
                    soldOutShopItemsMap[shopItem.id] = shopItem.stock < 0 ? 0 : shopItem.stock;
                  }
                });

                modifiersThatNeedStockChecks.forEach((modifierId) => {
                  const modifier = allModifiers.find((modifier) => modifier.id === modifierId);
                  const totalPurchaseQuantity = modifierQuantityBeingOrdered[modifierId];

                  if (!modifier) {
                    // Order item was delisted while the shop was open in this case so that should count as the thing being out of stock.
                    soldOutModifierMap[modifierId] = 0;
                  } else if (modifier.hasStock && modifier.stock < totalPurchaseQuantity) {
                    soldOutModifierMap[modifier.id] = modifier.stock < 0 ? 0 : modifier.stock;
                  }
                });

                Object.entries(soldOutShopItemsMap).forEach(([shopItemId, stockLeft]) => {
                  const shopItem = shopMode.shopItemList.find((shopItem) => shopItem.id === shopItemId);
                  if (shopItem) {
                    allSoldOutThingsNameToOverSoldAmountMap[shopItem.id] = {
                      name: shopItem.name,
                      stockLeft,
                      totalOrdered: shopItemQuantityBeingOrdered[shopItemId],
                    };
                  }
                });
                Object.entries(soldOutModifierMap).forEach(([modifierId, stockLeft]) => {
                  const modifier = allModifiers.find((modifier) => modifier.id === modifierId);
                  if (modifier) {
                    allSoldOutThingsNameToOverSoldAmountMap[modifier.id] = {
                      name: modifier.name,
                      stockLeft,
                      totalOrdered: modifierQuantityBeingOrdered[modifierId],
                    };
                  }
                });
              }
              resolve(allSoldOutThingsNameToOverSoldAmountMap);
            })
            .catch((error) => {
              showError(`Stock check failed. Error: ${error?.message || error}`);
              resolve(null);
            });
        } else {
          resolve(null);
        }
      });
    },
    // Required for creating the payment intent.
    stageOneValidationsPassed(isSilent = false) {
      const validationForm: Record<string, any> = {
        customerEmail: this.customerEmail,
      };

      const rules: FormValidationMapping = [[eValidationRules.requiredEmailRules, ['customerEmail']]];

      if (this.mode.requireName) {
        validationForm.customerName = this.customerName;
        rules.push([eValidationRules.requiredGenericStringRules, ['customerName']]);
      }

      const isValid = isFormValid(this, validationForm, rules, isSilent, {
        customerEmail: t.email,
        customerName: t.email,
      });

      if (!isValid) {
        return false;
      }

      if (this.mode.requiresDeliveryAddress && !this.deliveryAddress) {
        if (!isSilent) {
          showError(t.addressRequired);
        }
      }

      if (this.totalAmount <= 100) {
        if (!isSilent) {
          showError(t.belowMinimumChargeAmount);
        }
        return false;
      }

      if (!this.stripeAccount) {
        if (!isSilent) {
          showError('Vendor must connect a Stripe account to enable payments.');
        }
        return false;
      }

      if (!this.stripeClient) {
        if (!isSilent) {
          showError('Stripe client missing. You may need to add an exception to your adblocker or firewall.', true);
        }
        return false;
      }

      return true;
    },
    buildSitchLink(additionalDataForLink: string) {
      return isProductionEnv ? `https://sitch.app/${additionalDataForLink}` : `https://sitch-client-test.web.app/${additionalDataForLink}`;
    },
    // Required for sending the payment.
    stageTwoValidationsPassed(paidViaDigitalWallet: boolean) {
      if (!this.stageOneValidationsPassed()) {
        return;
      }

      if (!this.paymentIntent) {
        showError('Payment intent was not yet created.');
        return false;
      }

      if (!this.paymentIntent.client_secret) {
        showError('No Stripe client secret avaiable.');
        return false;
      }

      if (!this.cardElementInstance && !paidViaDigitalWallet) {
        showError('No Stripe card element instance avaiable.');
        return false;
      }

      if (!this.isCardValid && !paidViaDigitalWallet) {
        showError(t.invalidCreditCardInfo);
        return false;
      }

      if (!this.paymentRequestMethodEvent && paidViaDigitalWallet) {
        showError('No paymentRequestMethodEvent.');
        return;
      }

      // If there's custom fields then we have to temporarily save them in the DB.
      if (this.mode.preSubmissionCustomFormModeId) {
        const customFormVars = (this.$refs.preSubmissionForm as any).getCustomFormValidationAndData() as {
          areCustomFieldsValid: boolean;
          customFieldData: {
            [id: string]: CustomFieldData;
          };
        };
        this.customFieldData = customFormVars.customFieldData;
        if (!customFormVars.areCustomFieldsValid) {
          return false;
        }
      }

      this.submittingPayment = true;
      return true;
    },
    onPaymentConfirmed() {
      this.onPurchaseCompelete();
      Vue.nextTick(() => {
        scrollToTop();
      });
      removeLocalStorageItem('orderBreakdown');
      removeLocalStorageItem('orderBreakdownShopDocId');
    },
    onCardPaymentSubmit() {
      this.cardPaymentAttemptedSinceLastCardValidation = true;
      this.onSubmitCardOrWalletPayment(false);
    },
    async onSubmitCardOrWalletPayment(paidViaDigitalWallet: boolean) {
      if (!this.stageTwoValidationsPassed(paidViaDigitalWallet)) {
        return;
      }

      let cannotCompletePaymentMessage = '';

      //Last second stock checks.
      const soldOutItems = await this.getSoldOutItems();
      this.submittingPayment = false;

      if (soldOutItems && Object.keys(soldOutItems).length > 0) {
        Object.values(soldOutItems).forEach((oversoldData) => {
          const line = t.soldOutThing.supplant([oversoldData.totalOrdered, oversoldData.name, oversoldData.stockLeft]);
          cannotCompletePaymentMessage += line + '\n\n';
        });
        this.cannotCompletePaymentMessage = cannotCompletePaymentMessage;
        return;
      }

      const onConfirmCardPayment = () => {
        showLoading();
        const onSuccess = (result: any) => {
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            // TODO: There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.

            this.successfulPaymentIntent = result.paymentIntent;
            this.onPaymentConfirmed();

            analyzeClick(this.mode as AnyMode, {
              name: 'Payment complete',
              id: this.mode.docId + '_paymentComplete',
            });
            window.parent.postMessage(
              {
                _sitch_messageType: '_sitch_paymentConversion',
                amount: this.totalAmount,
                currency: this.mode.currency,
                orderBreakdown: this.orderBreakdown,
              },
              '*'
            );
          } else {
            nonDebugLog('Non capture payment result happened: ', result.paymentIntent);
          }
          hideLoading(true);
        };

        const onFailure = (result: any) => {
          // Show error to your customer (e.g., insufficient funds)
          hideLoading(true);
          analyzeClick(this.mode as AnyMode, {
            name: 'Payment failed',
            id: this.mode.docId + '_paymentFailed',
          });
          // Change the update object back to how it was
          showError(`${t.paymentFailed} ${result.error.message}`, true, result.error);
        };

        const paymentRequestMethodEvent = this.paymentRequestMethodEvent as PaymentRequestPaymentMethodEvent;
        const cardElementInstance = this.cardElementInstance as StripeCardElement;
        const clientSecret: string = this.paymentIntent?.client_secret as string;
        const confirmCardPaymentData = paidViaDigitalWallet ? paymentRequestMethodEvent.paymentMethod.id : { card: cardElementInstance };
        const options = paidViaDigitalWallet ? {} : { handleActions: false };

        // Actually send the payment.
        this.stripeClient
          ?.confirmCardPayment(clientSecret, { payment_method: confirmCardPaymentData }, options)
          .then((result) => {
            if (paidViaDigitalWallet) {
              const { error: confirmError } = result;
              if (!this.paymentRequestMethodEvent) {
                onFailure({
                  error: {
                    message: 'No paymentRequestMethodEvent available.',
                  },
                });
                hideLoading();
                return;
              }
              if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                this.paymentRequestMethodEvent.complete('fail');
                onFailure(result);
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                this.paymentRequestMethodEvent.complete('success');
                onSuccess(result);
                // Let Stripe.js handle the rest of the payment flow.
              }
            } else {
              if (result.error) {
                onFailure(result);
              } else {
                // The payment has been processed!
                onSuccess(result);
              }
            }
          })
          .catch((error: any) => {
            showError(`Could not confirm card payment: ${error?.message || error}`, true, error);
          })
          .finally(() => {
            this.submittingPayment = false;
            hideLoading();
          });
      };

      showNotice(t.sendingPaymentDoNotClose);
      showLoading();

      if (this.mode.preSubmissionCustomFormModeId) {
        standardApiFetch(endpoints.saveCustomFieldsForPayment, {
          paymentIntentId: this.paymentIntent?.id,
          customFields: this.customFieldData,
        })
          .then(onConfirmCardPayment)
          .catch((error: any) => {
            showError(`Could not save form data. You were not charged: ${error?.message || error}`, true, error);
            this.submittingPayment = false;
            hideLoading(true);
          });
      } else {
        onConfirmCardPayment();
      }
    },
  },
});
