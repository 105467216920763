import Vue, { PropType } from 'vue';
import { doc, setDoc } from 'firebase/firestore';
import { getInteractionData } from './util-functions/analytics-utils';
import { getMoneySymbolIfNeeded } from './util-functions/money-utils';
import { currFirestore } from './util-functions/initialization-util';
import { store } from './store';

export const modeMixin = Vue.extend({
  props: {
    givenMode: {
      type: Object as PropType<AnyMode>,
    },
  },
  created() {
    const mode: any = this.mode;
    if (!store.state.noTrack) {
      setDoc(doc(currFirestore, 'users', this.$store.state.userId, 'analytics', mode.docId), getInteractionData(mode), { merge: true });
    }
    if (mode.currency) {
      getMoneySymbolIfNeeded(mode.currency);
    }
  },
  computed: {
    mode(): AnyMode {
      return this.givenMode || this.$store.state.mode;
    },
  },
});
