
import Vue from 'vue';
import { eventBus } from '@/util-functions/initialization-util';
import { storeEventBus } from '@/store';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    hideX: {
      type: Boolean,
      default: false,
    },
    allowClose: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    onCloseCustomFunction: {
      type: Function,
      default: null,
    },
  },
  data(): {
    isActive: boolean;
    numberOfModalsAboveThisOne: number;
  } {
    return {
      isActive: this.value,
      numberOfModalsAboveThisOne: 0,
    };
  },
  computed: {
    modalContentStyle(): any {
      const headerHeight = 80;
      const footerHeight = this.$slots.modalControls ? 80 : 0;
      const modalMargin = 20;
      const occupiedSpace = headerHeight + footerHeight + modalMargin;
      return { overflow: this.numberOfModalsAboveThisOne > 0 ? 'hidden' : 'auto', maxHeight: `${this.$store.state.viewportHeight - occupiedSpace}px` };
    },
  },
  methods: {
    onDimmerClick() {
      if (this.allowClose) {
        this.closeThis();
      }
    },
    onXClick() {
      this.closeThis();
    },
    closeThis() {
      this.isActive = false;
      if (this.onCloseCustomFunction) {
        this.onCloseCustomFunction();
      } else {
        this.$emit('input', this.isActive);
      }
      storeEventBus.$emit('decreaseModalDepth');
    },
    openThis() {
      this.isActive = true;
      this.$emit('input', this.isActive);
      storeEventBus.$emit('increaseModalDepth');
      Vue.nextTick(() => {
        this.numberOfModalsAboveThisOne = 0;
      }); // Need a delay to unsupress ourselves.
    },
  },
  mounted() {
    eventBus.$on('closeModals', () => {
      this.closeThis();
      this.numberOfModalsAboveThisOne = 0;
    });
    // We have to track modal depth because of limitations in mobile Safari around nested elements that are supposed to be larger than their parents but their parentst have a set max height and overflow auto.
    eventBus.$on('decreaseModalDepth', () => {
      this.numberOfModalsAboveThisOne = this.numberOfModalsAboveThisOne > 0 ? this.numberOfModalsAboveThisOne - 1 : 0;
    });
    eventBus.$on('increaseModalDepth', () => {
      this.numberOfModalsAboveThisOne++;
    });
  },
  watch: {
    value(isActive) {
      if (isActive) {
        this.openThis();
      } else {
        this.closeThis();
      }
    },
    $route() {
      this.closeThis();
    },
  },
});
