import Vue from 'vue';
import Vuex from 'vuex';
import { changeLanguage, currFirestore, setMode, sitchClientUrl } from '@/util-functions/initialization-util';
import { eCardStyle, eInteractionDeviceTypes, eInteractionMethods, eMetaTheme, eModeType, eTranslationLocaleCode } from './enums';
import {
  defaultThemes,
  getEmptyAccordionMode,
  getEmptyAccordionSection,
  getEmptyBasicMode,
  getEmptyBlogMode,
  getEmptyBlogPostReference,
  getEmptyBookingMode,
  getEmptyBusinessPaymentsMode,
  getEmptyCustomFormMode,
  getEmptyEventsMode,
  getEmptyFilesMode,
  getEmptyGalleryItem,
  getEmptyGalleryMode,
  getEmptyGroupMode,
  getEmptyHtmlMode,
  getEmptyLink,
  getEmptyLinkListMode,
  getEmptyPersonalPaymentsMode,
  getEmptyProfileMode,
  getEmptyShopMode,
  getEmptySitchEvent,
  getEmptySiteMode,
  getEmptyArticleMode,
  getEmptyUrlRedirectMode,
  getEmptyWifiMode,
} from './constants';
import { DocumentReference, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { initTheme } from './util-functions/theme-utils';
import { incrementUniqueViews } from './util-functions/analytics-utils';
import { showError, showGlobalModal } from './util-functions/notice-utils';

Vue.use(Vuex);

// Cannot import this from util so redeclaring here. This also doesn't factor in provided urls.
const getUrlParamByNameForStore = (n: string, givenUrl = ''): string => {
  const url: string = givenUrl || window.location.href;
  const name = n.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getLocalStorageItem = (item = ''): string | null => {
  try {
    if (window.localStorage) {
      return localStorage?.getItem(item) || null;
    }
  } catch {
    return null;
  }
  return null;
};

export const setLocalStorageItem = (item: string, value = ''): boolean => {
  try {
    if (window.localStorage && item) {
      localStorage?.setItem(item, value);
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const removeLocalStorageItem = (item = ''): boolean => {
  try {
    if (window.localStorage) {
      localStorage?.removeItem(item);
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

const previewTheme: AestheticTheme = {
  id: 'preview',
  name: 'preview',
  backgroundColor: getUrlParamByNameForStore('bc') ? getUrlParamByNameForStore('bc').replaceAll('%23', '#') : '',
  images:
    getUrlParamByNameForStore('bi') !== 'none'
      ? [
          {
            largeImageUrl: getUrlParamByNameForStore('bi'),
          } as UploadedImage,
        ]
      : [],
  secondaryImages:
    getUrlParamByNameForStore('li') !== 'none'
      ? [
          {
            largeImageUrl: getUrlParamByNameForStore('li'),
          } as UploadedImage,
        ]
      : [],
  accentColor: getUrlParamByNameForStore('ac') ? getUrlParamByNameForStore('ac').replaceAll('%23', '#') : '',
  primaryFontName: getUrlParamByNameForStore('pfn'),
  headerFontName: getUrlParamByNameForStore('hfn'),
  primaryFontVariant: getUrlParamByNameForStore('pfv') as FontVariant,
  headerFontVariant: getUrlParamByNameForStore('hfv') as FontVariant,
  primaryFontScale: parseFloat(getUrlParamByNameForStore('pfs')),
  headerFontScale: parseFloat(getUrlParamByNameForStore('hfs')),
  uppercaseHeaderFont: getUrlParamByNameForStore('hft') === 'true',
  cardStyle: getUrlParamByNameForStore('cs') as eCardStyle,
  ambientText: getUrlParamByNameForStore('at'),
  metaTheme: getUrlParamByNameForStore('mt') as eMetaTheme,
};

export const storeEventBus = new Vue();

export const getEmptyUserModeGateway = (): PublicUserModeGateway => {
  return {
    docId: '',
    activeModeId: '',
    wrapperModeId: '',
    activeModeLinkId: '',
    wrapperModeLinkId: '',
    activeModeOwnerId: '',
    wrapperModeOwnerId: '',
    isSitchLinkActivated: false,
    hasPermanentPremium: false,
    loadNewActiveModesAutomatically: false,
    autoSitchTimer: 1000 * 60 * 10, // 10 mins by default.
    premiumSubscriptionId: '',
    stripeAccountsMap: null,
    themes: {},
    teamOwnerGatewayRef: null,
    assignedTeamThemeId: '',
  };
};

interface State {
  isLoading: boolean;
  isAutoSitchDisabled: boolean;
  customPermalinkId: string;
  sitePages: Mode[];
  groupModes: Mode[];
  mode: Mode | null;
  userId: string;
  i18n: any;
  rootComponent: any;
  publicUserModeGateway: PublicUserModeGateway;
  publicUserModeGatewayDoc: DocumentReference | null;
  genericIdToUserIdMapping: GenericIdData | null;
  firstLoadHadActiveModeInUrl: boolean;
  activeModeWasSetAutomatically: boolean;
  savedMoneySymbols: { [currency: string]: string };
  currSite: SiteMode | null;
  isLightTheme: boolean;
  isPremiumActive: boolean;
  locale: eTranslationLocaleCode;
  isOnRedirectPage: boolean;
  pushToken: string;
  viewportHeight: number;
  queryStringAmount: number;
  brandIconsAlreadyAdded: boolean;
  fileIconsAlreadyAdded: boolean;
  itemId: string;
  itemNameInUrl: string;
  isEmbedded: boolean;
  embeddedWidth: number;
  finishedModeAcquisitionProcess: boolean;
  wasGivenViaInAppQrCode: boolean;
  modeChangeWasDueToPopState: boolean;
  orderBreakdownInUrl: OrderItem[];
  promoCodeInUrl: string;
  settingEmbededMode: boolean;
  noTrack: boolean;
  interactionMethod: eInteractionMethods;
  genericId: string;
  visitedRoot: boolean;
  ambientText: string;
}

function initialState() {
  const localStorageSavedMoneySymbols = getLocalStorageItem('savedMoneySymbols');

  const browserLang = navigator.language || navigator.languages?.[0] || eTranslationLocaleCode.en;
  const commonMoneySymbols = { USD: 'US$', EUR: '€', CAD: 'CA$' };

  const state: State = {
    isLoading: false,
    isAutoSitchDisabled: false,
    customPermalinkId: '',
    mode: null,
    sitePages: [],
    groupModes: [],
    userId: '',
    i18n: null,
    rootComponent: null,
    publicUserModeGateway: getEmptyUserModeGateway(),
    publicUserModeGatewayDoc: null,
    genericIdToUserIdMapping: null,
    firstLoadHadActiveModeInUrl: false,
    activeModeWasSetAutomatically: false,
    savedMoneySymbols: localStorageSavedMoneySymbols ? { ...JSON.parse(localStorageSavedMoneySymbols), ...commonMoneySymbols } : { ...commonMoneySymbols },
    currSite: null,
    isLightTheme: true,
    isPremiumActive: false,
    locale: ((getLocalStorageItem('locale') || browserLang).split('-')[0] || eTranslationLocaleCode.en) as eTranslationLocaleCode,
    isOnRedirectPage: false,
    pushToken: '',
    viewportHeight: window.innerHeight,
    queryStringAmount: 0,
    brandIconsAlreadyAdded: false,
    fileIconsAlreadyAdded: false,
    itemId: '',
    itemNameInUrl: '',
    isEmbedded: false,
    embeddedWidth: 0,
    finishedModeAcquisitionProcess: false,
    wasGivenViaInAppQrCode: false,
    modeChangeWasDueToPopState: false,
    orderBreakdownInUrl: [],
    promoCodeInUrl: '',
    settingEmbededMode: false,
    noTrack: false,
    interactionMethod: eInteractionMethods.link,
    genericId: '',
    visitedRoot: false,
    ambientText: '',
  };
  return state;
}

export const store = new Vuex.Store({
  state: initialState(),
  mutations: {
    reset(state) {
      // acquire initial state
      const s: State = initialState();
      Object.keys(s).forEach((key: string) => {
        (state as any)[key] = (s as any)[key];
      });
    },
    orderBreakdownInUrl(state, payload: OrderItem[]) {
      state.orderBreakdownInUrl = payload;
    },
    promoCodeInUrl(state, payload: string) {
      state.promoCodeInUrl = payload;
    },
    finishedModeAcquisitionProcess(state, payload: boolean) {
      state.finishedModeAcquisitionProcess = payload;
    },
    brandIconsAlreadyAdded(state, payload: boolean) {
      state.brandIconsAlreadyAdded = payload;
    },
    fileIconsAlreadyAdded(state, payload: boolean) {
      state.fileIconsAlreadyAdded = payload;
    },
    modeChangeWasDueToPopState(state, payload: boolean) {
      state.modeChangeWasDueToPopState = payload;
    },
    queryStringAmount(state, payload: number) {
      state.queryStringAmount = payload;
    },
    isEmbedded(state, payload: boolean) {
      if (!state.isEmbedded) {
        // Cannot be made false once true.
        state.isEmbedded = payload;
      }
    },
    embeddedWidth(state, payload: number) {
      state.embeddedWidth = payload;
    },
    isLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
    viewportHeight(state, payload: number) {
      state.viewportHeight = payload;
    },
    pushToken(state, payload: string) {
      state.pushToken = payload;
    },
    itemId(state, payload: string) {
      state.itemId = payload;
    },
    itemNameInUrl(state, payload: string) {
      state.itemNameInUrl = payload;
    },
    isOnRedirectPage(state, payload: boolean) {
      state.isOnRedirectPage = payload;
    },
    isPremiumActive(state, payload: boolean) {
      state.isPremiumActive = payload;
    },
    locale(state, payload: eTranslationLocaleCode) {
      state.locale = payload;
      changeLanguage();
    },
    isLightTheme(state, payload: boolean) {
      state.isLightTheme = payload;
    },
    currSite(state, payload: SiteMode) {
      state.currSite = payload;
    },
    sitePages(state, payload: Mode[]) {
      state.sitePages = payload;
    },
    groupModes(state, payload: Mode[]) {
      state.groupModes = payload;
    },
    firstLoadHadActiveModeInUrl(state, payload: boolean) {
      state.firstLoadHadActiveModeInUrl = payload;
    },
    genericIdToUserIdMapping(state, payload: GenericIdData) {
      state.genericIdToUserIdMapping = payload;
    },
    publicUserModeGateway(state, payload: PublicUserModeGateway) {
      state.publicUserModeGateway = {
        ...getEmptyUserModeGateway(),
        ...payload,
      };
    },
    wasGivenViaInAppQrCode(state, payload: boolean) {
      state.wasGivenViaInAppQrCode = payload;
    },
    isAutoSitchDisabled(state, payload: boolean) {
      state.isAutoSitchDisabled = payload;
    },
    publicUserModeGatewayDoc(state, payload: DocumentReference) {
      state.publicUserModeGatewayDoc = payload;
      state.activeModeWasSetAutomatically = false;
      if (
        !state.isEmbedded &&
        state.publicUserModeGateway?.loadNewActiveModesAutomatically &&
        state.publicUserModeGateway?.isSitchLinkActivated &&
        (!state.firstLoadHadActiveModeInUrl || state.wasGivenViaInAppQrCode)
      ) {
        const unsubDocumentFunc = onSnapshot(payload, (watchedDocSnap) => {
          // We don't want to automatically update if the user didn't load sitch.app from a card or QR code, so check firstLoadHadActiveModeInUrl.
          if (watchedDocSnap.exists()) {
            const publicUserModeGateway = watchedDocSnap.data() as PublicUserModeGateway;
            // If something relevant has changed.
            if (
              publicUserModeGateway.activeModeId !== state.publicUserModeGateway.activeModeId ||
              (publicUserModeGateway.wrapperModeId !== state.publicUserModeGateway.wrapperModeId && !state.isAutoSitchDisabled)
            ) {
              state.publicUserModeGateway = publicUserModeGateway;
              // If not the same mode.
              state.sitePages = [];

              // This prevents the auto update from overwriting a quick initial set from sitch buttons on embedded sitch sites.
              if (state.finishedModeAcquisitionProcess) {
                setMode(publicUserModeGateway);
              }

              state.activeModeWasSetAutomatically = true;
              state.currSite = null;
              (document as Document).getElementById('app')?.scrollTo(0, 0);
            }

            // AutoSitch was turned off in this case so kill the connection.
            if (!publicUserModeGateway.loadNewActiveModesAutomatically && unsubDocumentFunc) {
              unsubDocumentFunc();
            }

            if (publicUserModeGateway.autoSitchTimer) {
              setTimeout(() => {
                unsubDocumentFunc();
              }, publicUserModeGateway.autoSitchTimer);
            }
          }
        });
      }
    },
    mode(state, payload: AnyMode) {
      if (!payload) {
        state.mode = null;
        return;
      }

      if (state.genericIdToUserIdMapping && payload.type) {
        const isPaymentRestricedCardType = state.genericIdToUserIdMapping.interactionDeviceType === eInteractionDeviceTypes.umbCard;

        if (isPaymentRestricedCardType && [eModeType.businessPayment, eModeType.shop].includes(payload.type)) {
          showGlobalModal('Restricted Sitch Type', 'Payment Sitches cannot be shown via a UMB branded card.');
          return;
        }
      }

      let emptyModeTemplate = {};

      switch (payload.type) {
        case eModeType.profile:
          emptyModeTemplate = getEmptyProfileMode();
          break;
        case eModeType.linkList:
          emptyModeTemplate = getEmptyLinkListMode();
          break;
        case eModeType.businessPayment:
          emptyModeTemplate = getEmptyBusinessPaymentsMode();
          break;
        case eModeType.files:
          emptyModeTemplate = getEmptyFilesMode();
          break;
        case eModeType.gallery:
          emptyModeTemplate = getEmptyGalleryMode();
          break;
        case eModeType.group:
          emptyModeTemplate = getEmptyGroupMode();
          break;
        case eModeType.site:
          emptyModeTemplate = getEmptySiteMode();
          break;
        case eModeType.html:
          emptyModeTemplate = getEmptyHtmlMode();
          break;
        case eModeType.shop:
          emptyModeTemplate = getEmptyShopMode();
          break;
        case eModeType.personalPayment:
          emptyModeTemplate = getEmptyPersonalPaymentsMode();
          break;
        case eModeType.wifi:
          emptyModeTemplate = getEmptyWifiMode();
          break;
        case eModeType.urlRedirect:
          emptyModeTemplate = getEmptyUrlRedirectMode();
          break;
        case eModeType.article:
          emptyModeTemplate = getEmptyArticleMode();
          break;
        case eModeType.booking:
          emptyModeTemplate = getEmptyBookingMode();
          break;
        case eModeType.events:
          emptyModeTemplate = getEmptyEventsMode();
          break;
        case eModeType.blog:
          emptyModeTemplate = getEmptyBlogMode();
          break;
        case eModeType.accordion:
          emptyModeTemplate = getEmptyAccordionMode();
          break;
        case eModeType.customForm:
          emptyModeTemplate = getEmptyCustomFormMode();
          break;
      }

      const newModeToSet: AnyMode = {
        ...getEmptyBasicMode(),
        ...emptyModeTemplate,
        ...payload,
      };

      // Fill in any missing or newly added fields on the item arrays for the mode being loaded in.
      for (const prop of Object.keys(newModeToSet)) {
        if (!Object.keys(getEmptyBasicMode()).includes(prop)) {
          // To make sure we don't override the parent forms generic mode fields.
          if (Array.isArray((newModeToSet as any)[prop])) {
            let itemTemplate: any = null;
            switch (`${newModeToSet.type}.${prop}`) {
              case `${eModeType.linkList}.links`:
                itemTemplate = getEmptyLink();
                break;
              case `${eModeType.gallery}.galleryItems`:
                itemTemplate = getEmptyGalleryItem();
                break;
              case `${eModeType.linkList}.postReferences`:
                itemTemplate = getEmptyBlogPostReference();
                break;
              case `${eModeType.accordion}.sections`:
                itemTemplate = getEmptyAccordionSection();
                break;
              case `${eModeType.shop}.shopItemList`:
                itemTemplate = getEmptyLink();
                break;
              case `${eModeType.events}.events`:
                itemTemplate = getEmptySitchEvent();
                break;
            }

            if (itemTemplate) {
              (newModeToSet as any)[prop] = (newModeToSet as any)[prop].map((el: any) => {
                return {
                  ...itemTemplate,
                  ...el,
                };
              });
            }
          }
        }
      }

      let recentModes: HistoryMode[] = JSON.parse(getLocalStorageItem('recentModes') || '[]');
      // Only add to the most recent modes if you didn't just visit the same one.
      if (recentModes[0]?.docId !== newModeToSet.docId) {
        recentModes.unshift({
          docId: newModeToSet.docId,
          linkId: newModeToSet.linkId,
          type: newModeToSet.type,
          name: newModeToSet.name,
          displayName: newModeToSet.displayName,
          dateVisited: Date.now(),
          fullUrl: `${sitchClientUrl}/s/${newModeToSet.linkId}`,
        });
        // Only keep the most recent 50 in local storage.
        recentModes = recentModes.slice(0, 50);
        setLocalStorageItem('recentModes', JSON.stringify(recentModes));
      }

      state.mode = newModeToSet;

      let themes: Record<string, AestheticTheme> = {
        ...defaultThemes,
        ...state.publicUserModeGateway?.themes,
      };

      async function initModeTheme(givenTheme: AestheticTheme | null = null) {
        let assignedTeamTheme: AestheticTheme | null = null;
        const teamOwnerGatewayRef = state.publicUserModeGateway.teamOwnerGatewayRef;
        const assignedTeamThemeId = state.publicUserModeGateway.assignedTeamThemeId;

        if (assignedTeamThemeId) {
          if (!teamOwnerGatewayRef) {
            console.error(`Could not show theme, no teamOwnerGatewayRef.`);
            return;
          }

          await getDoc(teamOwnerGatewayRef)
            .then((docSnap) => {
              const teamOwnerGateway = docSnap.data() as PublicUserModeGateway;
              assignedTeamTheme = teamOwnerGateway.themes?.[assignedTeamThemeId];
            })
            .catch((error) => {
              showError(`Could not get gateway for the team owner. ${error}`);
            });
        }

        let newTheme: AestheticTheme | undefined = givenTheme || assignedTeamTheme || themes[payload.themeId];
        const thisisForTeamModeThatHasTheme = !newTheme && newModeToSet.themeId;

        // In this case. a Team Mode has a theme and we have to look it up in the team owner's gateway.
        if (thisisForTeamModeThatHasTheme) {
          const activeModeOwnerId = state.publicUserModeGateway?.activeModeOwnerId;

          if (!activeModeOwnerId) {
            console.error(`Could not show theme, no activeModeOwnerId.`);
            return;
          }

          const modeOwnerGatewayDoc = doc(currFirestore, 'publicUserModeGateways', activeModeOwnerId);

          await getDoc(modeOwnerGatewayDoc)
            .then((docSnap) => {
              const modeOwnerGateway = docSnap.data() as PublicUserModeGateway;
              newTheme = modeOwnerGateway.themes?.[newModeToSet.themeId];
            })
            .catch((error) => {
              showError(`Could not get gateway for the team owner. ${error}`);
            });
        }

        const modeId = newModeToSet.docId;
        const { activeModeId, activeModeOwnerId, wrapperModeId, wrapperModeOwnerId } = state.publicUserModeGateway;
        const modeToBeSetIsExternalActive = activeModeId === modeId && activeModeOwnerId;
        const modeToBeSetIsExternalWrapper = wrapperModeId === modeId && wrapperModeOwnerId;
        if (modeToBeSetIsExternalActive || modeToBeSetIsExternalWrapper) {
          // In this case we're loading the theme for an external mode.
          let docToUse;
          if (modeToBeSetIsExternalActive) {
            docToUse = doc(currFirestore, 'publicUserModeGateways', activeModeOwnerId, 'modes', activeModeId);
          } else {
            docToUse = doc(currFirestore, 'publicUserModeGateways', wrapperModeOwnerId, 'modes', wrapperModeId);
          }
          getDoc(docToUse).then((docSnap) => {
            if (docSnap.exists()) {
              const gateway = docSnap.data() as PublicUserModeGateway;
              themes = {
                ...defaultThemes,
                ...gateway.themes,
              };
            }
            initTheme(newTheme);
          });
        } else {
          initTheme(newTheme);
        }
      }

      if (getUrlParamByNameForStore('themeprev')) {
        initTheme(previewTheme);
      } else {
        if (state.currSite?.themeId) {
          const newTheme = themes[state.currSite?.themeId];
          if (newTheme) {
            initModeTheme(newTheme);
          } else {
            initModeTheme();
          }
        } else {
          initModeTheme();
        }
      }
      window.document.title = newModeToSet.displayName || newModeToSet.name || 'Sitch';
    },
    userId(state, payload: string) {
      incrementUniqueViews(payload);
      state.userId = payload;
    },
    customPermalinkId(state, payload: string) {
      state.customPermalinkId = payload;
    },
    i18n(state, payload: any) {
      state.i18n = payload;
    },
    rootComponent(state, payload: any) {
      state.rootComponent = payload;
    },
    savedMoneySymbols(state, [currency, symbol]: [string, string]) {
      state.savedMoneySymbols[currency] = symbol;
      setLocalStorageItem('savedMoneySymbols', JSON.stringify(state.savedMoneySymbols));
      storeEventBus.$emit('moneySymbolsUpdated');
    },
    settingEmbededMode(state, payload: boolean) {
      state.settingEmbededMode = payload;
    },
    noTrack(state, payload: boolean) {
      state.noTrack = payload;
    },
    interactionMethod(state, payload: eInteractionMethods) {
      state.interactionMethod = payload;
    },
    genericId(state, payload: string) {
      state.genericId = payload;
    },
    visitedRoot(state, payload: boolean) {
      state.visitedRoot = payload;
    },
    ambientText(state, payload: string) {
      state.ambientText = payload;
    },
  },
});

window.addEventListener('resize', function () {
  store?.commit('viewportHeight', window.innerHeight);
});
