import { t } from './util-functions/initialization-util';
import { showError } from './util-functions/notice-utils';

const maxGenericStringLength = 500;
const maxGenericLongStringLength = 5000;
const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const phoneRegEx = /^[0-9()+-\s]+$/;

export enum eValidationRules {
  genericStringRules = 'genericStringRules',
  requiredGenericStringRules = 'requiredGenericStringRules',
  emailRules = 'emailRules',
  requiredEmailRules = 'requiredEmailRules',
  phoneRules = 'phoneRules',
  requiredPhoneRules = 'requiredPhoneRules',
  urlRules = 'urlRules',
  requiredUrlRules = 'requiredUrlRules',
  genericLongStringRules = 'genericLongStringRules',
  requiredGenericLongStringRules = 'requiredGenericLongStringRules',
  requiredCustomFormAddress = 'requiredCustomFormAddress',
  validGoogleAddress = 'validGoogleAddress',
  required = 'required',
}

interface ValidationRules {
  [ruleName: string]: ValidationFunc[]; // rule key, properties value
}

type ValidationFunc = (v: string) => true | string;

const validationRules: ValidationRules = {
  genericStringRules: [(v: string) => (v ? v.length < maxGenericStringLength || t.mustBeLessThan.supplant([maxGenericStringLength.toString()]) : true)],
  requiredGenericStringRules: [(v: string) => !!v || t.isRequired, (v: string) => (v ? v.length < maxGenericStringLength || t.mustBeLessThan.supplant([maxGenericStringLength.toString()]) : true)],
  emailRules: [(v: string) => (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || t?.mustBeValidEmail : true)],
  requiredEmailRules: [(v: string) => !!v || t.isRequired, (v: string) => (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || t.mustBeValidEmail : true)],
  phoneRules: [(v: string) => (v ? phoneRegEx.test(v) || t.mustBeValidPhoneNumber : true)],
  requiredPhoneRules: [(v: string) => !!v || t.isRequired, (v: string) => (v ? phoneRegEx.test(v) || t.mustBeValidPhoneNumber : true)],
  urlRules: [(v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true)],
  requiredUrlRules: [(v: string) => !!v || t.isRequired, (v: string) => (v ? URL_REGEX.test(v) || t?.mustBeValidUrl : true)],
  genericLongStringRules: [(v: string) => (v ? v.length < maxGenericLongStringLength || t.mustBeLessThan.supplant([maxGenericLongStringLength.toString()]) : true)],
  requiredGenericLongStringRules: [
    (v: string) => !!v || t.isRequired,
    (v: string) => (v ? v.length < maxGenericLongStringLength || t.mustBeLessThan.supplant([maxGenericLongStringLength.toString()]) : true),
  ],
  requiredCustomFormAddress: [(v: string) => !!v || t.isRequired, (v: string) => (v ? !v.endsWith(', ') || t.incompleteAddress : true)],
  required: [(v: any) => !!v || v === false || v === 0 || t.isRequired],
};

export type FormValidationMapping = Array<[eValidationRules, string[]]>; // rule key, properties value

export const isFormValid = (vueComponent: any, formData: any, mapping: FormValidationMapping, quietValidation = false, propToFieldLabelMapping: Record<string, string> = {}) => {
  let isValid = false;

  const isPropertyValid = (str: string, ruleSet: ValidationFunc[]) => {
    return ruleSet.every((rule: ValidationFunc) => {
      const pathArray = str.split('.');
      let translationString = str;      
      let currVal = formData[pathArray[0]];      
      pathArray.shift();
      pathArray.forEach((currProp) => {
        if (currProp) {
          translationString = currProp;          
          currVal = currVal[currProp];
        }
      });      
      const result = rule(currVal);
      let formElement = vueComponent.$refs[str]?.[0] || vueComponent.$refs[str];
      formElement = formElement?.$el || formElement;
      if (result !== true) {
        // Result will be true if test was passed otherwise it'll be an error string.
        if (!quietValidation) {
          showError(t.validationError.supplant([t[translationString] || propToFieldLabelMapping[str] || translationString, result]));
          if (formElement) {
            formElement.classList?.add('show-error');
            formElement.focus();
          }
        }
        return false;
      }
      if (formElement) {
        formElement.classList?.remove('show-error');
      }
      return true;
    });
  };

  isValid = mapping.every(([rule, propertyArray]) => {
    // Check that every property in every property array passes.
    return propertyArray.every((property) => {
      return isPropertyValid(property, validationRules[rule]);
    });
  });

  return isValid;
};
