
import Vue from 'vue';
import { currFirestore, getParameterByName, t } from '@/util-functions/initialization-util';
import { endpoints } from '@/constants';
import { standardApiFetch } from '@/util-functions/api-utils';
import { showError } from '@/util-functions/notice-utils';
import { doc, getDoc } from 'firebase/firestore';

export default Vue.extend({
  data(): {
    message: string;
    successfulAction: boolean | null;
  } {
    return {
      message: '',
      successfulAction: null,
    };
  },
  mounted() {
    // Link format example: https://sitch.app/LinkActions?a=b&action=action.
    const action: string = getParameterByName('action');

    switch (action) {
      case 'verifyEmail':
        {
          const emailToVerify: string = getParameterByName('emailToVerify');
          const userId: string = getParameterByName('userId');
          const securityKey: string = getParameterByName('securityKey');

          standardApiFetch(endpoints.verifyEmail, {
            emailToVerify,
            userId,
            securityKey,
          })
            .then(() => {
              this.successfulAction = true;
              this.message = t.emailVerfied;
            })
            .catch((response) => {
              this.successfulAction = false;
              this.message = response.errorMessage;
            });
        }
        return;
      case 'cancelBooking':
        {
          const userId: string = getParameterByName('userId');
          const modeId: string = getParameterByName('modeId');
          const securityKey: string = getParameterByName('securityKey');
          const bookingDocId: string = getParameterByName('bookingId');

          getDoc(doc(currFirestore, 'publicUserModeGateways', userId, 'modes', modeId))
            .then((modeDocSnap) => {
              if (modeDocSnap.exists()) {
                const mode = modeDocSnap.data() as BookingMode;
                standardApiFetch(endpoints.cancelBooking, {
                  userId,
                  modeId,
                  securityKey,
                  bookingDocId,
                  emailForReceivingNotifications: mode.emailForReceivingNotifications,
                  locale: navigator?.languages[0] || navigator.language || '',
                })
                  .then(() => {
                    this.message = t.cancellationSuccessful;
                    this.successfulAction = true;
                  })
                  .catch(() => {
                    this.successfulAction = false;
                    this.message = t.cancellationUnsuccessful.supplant([mode.emailForReceivingNotifications]);
                  });
              } else {
                showError('Mode does not exist');
                this.successfulAction = false;
                this.message = t.cancellationUnsuccessfulNoMode;
              }
            })
            .catch((error: any) => {
              showError('Error getting mode. ' + error?.message);
              this.successfulAction = false;
              this.message = t.cancellationUnsuccessfulNoMode;
            });
        }
        return;
      default:
        showError('Unknown action');
        return;
    }
  },
});
