import { store } from "@/store";

let loadingCount = 0;

export const showLoading = () => {
  if (navigator.onLine) {
    loadingCount++;
    setTimeout(() => {
      // Slight delay in case we call hideLoading immeadiately after because of an error or something of that nature.
      if (loadingCount > 0) {
        store.commit('isLoading', true);
      }
    }, 0);
  }
};

export const hideLoading = (forceLoadingCountToZero = false) => {
  loadingCount--;
  if (forceLoadingCountToZero) {
    loadingCount = 0;
  }
  setTimeout(() => {
    if (loadingCount <= 0) {
      loadingCount = 0;
      store.commit('isLoading', false);
    }
  }, 100); // Anti FOUC delay.
};
