
import Vue, { PropType } from 'vue';
import { eventBus } from '@/util-functions/initialization-util';
import { formatCurrency } from '@/util-functions/money-utils';

export default Vue.extend({
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    onDeleteClick: {
      type: Function,
      default: (): void => undefined,
    },
  },
  mounted() {
    eventBus.$on('moneySymbolsUpdated', () => {
      this.$forceUpdate();
    });
  },
  methods: {
    formatModifierPrice(price: number, showSign = false): string {
      return formatCurrency(price, this.currency, showSign);
    },
  },
});
