
import Vue, { PropType } from 'vue';
import { STANDARD_UPLOAD_LIMIT_IN_BYTES } from '@/constants';
import { faFile, faFileImage, faFileAudio, faFileVideo, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileAlt, faFileCode, faFileArchive } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { store } from '@/store';
import 'firebase/storage';
import { compressImage, generateId, getFileIcon } from '@/util-functions/misc-utils';
import { showError } from '@/util-functions/notice-utils';
import { t } from '@/util-functions/initialization-util';

if (!store.state.fileIconsAlreadyAdded) {
  library.add(faFile, faFileImage, faFileAudio, faFileVideo, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileAlt, faFileCode, faFileArchive);
  store.commit('fileIconsAlreadyAdded', true);
}

const SMALL_FILE_IMAGE_MAX_DIMENSION = 150;
const LARGE_FILE_IMAGE_MAX_DIMENSION = 1920;

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<CompressedFile[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOptionalLabel: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    localImage: LogoImage | null;
    showImageCropDialog: boolean;
    imageSizes: ImageSizes;
    files: StorageFile[];
  } {
    return {
      localImage: null,
      showImageCropDialog: false,
      imageSizes: { small: 1000 },
      files: [],
    };
  },
  computed: {
    input(): HTMLInputElement {
      let input: HTMLInputElement = this.$refs.fileInput as HTMLInputElement;
      return input;
    },
  },
  methods: {
    getFileIcon(mineType: string) {
      return getFileIcon(mineType);
    },
    onUploadClick() {
      this.input.click();
    },
    removeFile(fileId: string) {
      const val = this.value.filter((file) => file.id !== fileId);
      this.$emit('change', val);
      this.$emit('input', val);
    },
    onUploadFiles() {
      let newUploadedFiles = Array.from(this.input.files || []);

      if (this.input.files) {
        this.input.value = '';
      }

      const FILES_MAX = 10;
      if (newUploadedFiles.length + this.files.length > FILES_MAX) {
        showError(t?.filesMax.supplant([FILES_MAX]));
        return;
      }

      if (newUploadedFiles.length) {
        const compressedUploadedFileBatch: CompressedFile[] = [];
        const promiseArray: Promise<void>[] = [];
        newUploadedFiles.forEach((file) => {
          if (file.type.includes('image')) {
            promiseArray.push(
              new Promise((resolve, reject) => {
                compressImage(file, SMALL_FILE_IMAGE_MAX_DIMENSION)
                  .then((imagePreviewFileObj) => {
                    compressImage(file, LARGE_FILE_IMAGE_MAX_DIMENSION)
                      .then((compressedFileObj) => {
                        if (compressedFileObj.compressedFile.size > STANDARD_UPLOAD_LIMIT_IN_BYTES) {
                          reject();
                          showError(t.fileTooLarge);
                          return;
                        }
                        compressedUploadedFileBatch.push({
                          id: generateId(),
                          file: compressedFileObj.compressedFile,
                          fileName: compressedFileObj.compressedFile.name,
                          description: '',
                          mimeType: compressedFileObj.compressedFile.type,
                          base64ImagePreview: imagePreviewFileObj.base64data,
                        });
                        resolve();
                      })
                      .catch((error: any) => {
                        reject();
                        showError(`Main image compression failed. ${error?.message || error}`, true);
                      });
                  })
                  .catch((error: any) => {
                    reject();
                    showError(`Preview image compression failed. ${error?.message || error}`, true);
                  });
              })
            );
          } else {
            if (file.size > STANDARD_UPLOAD_LIMIT_IN_BYTES) {
              return;
            }
            compressedUploadedFileBatch.push({
              id: generateId(),
              file,
              fileName: file.name,
              description: '',
              mimeType: file.type,
            });
          }
        });
        Promise.all(promiseArray).then(() => {
          const val = [...this.value, ...compressedUploadedFileBatch];
          this.$emit('change', val);
          this.$emit('input', val);
        });
      }
    },
    openFileInput() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
  },
});
