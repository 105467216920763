
import { generateId } from '@/util-functions/misc-utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      required: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TextValue[]>,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOptionalLabel: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    id: string;
    isDropdownActive: boolean;
    localValue: string;
    search: string;
  } {
    return {
      id: generateId(),
      isDropdownActive: false,
      localValue: this.items.find((item) => item.value === this.value)?.text || '',
      search: '',
    };
  },
  computed: {
    filteredItems(): TextValue[] {
      return this.items.filter((item) => {
        return item.text?.toLowerCase().includes(this.search?.toLowerCase() || '');
      });
    },
  },
  watch: {
    value(v) {
      this.localValue = this.items.find((item) => item.value === v)?.text || '';
    },
    isDropdownActive(v) {
      if (v) {
        const searchField = this.$refs.searchField as any;
        document.addEventListener('keydown', this.onKeyPress);
        Vue.nextTick(() => {
          searchField?.focus();
        });
      } else {
        document.removeEventListener('keypress', this.onKeyPress);
      }
    },
  },
  methods: {
    onSelect(value: any) {
      this.isDropdownActive = false;
      this.$emit('change', value);
      this.$emit('input', value);
    },
    onAutoComplete(e: Event) {
      const autoCompleteValue: string = (e.target as HTMLInputElement).value || '';
      if (autoCompleteValue) {
        const matchingItem = this.items.find((i) => i.text === autoCompleteValue);
        if (matchingItem?.value) {
          this.$emit('input', matchingItem.value);
        }
      }
    },
    onKeyPress(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowDown':
          {
            const searchField = this.$refs.searchField as any;
            if (searchField?.isFocused) {
              const firstDropdownItem = (this.$refs.itemButtons as any)?.[0];
              firstDropdownItem?.focus();
            }
          }
          break;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.onKeyPress);
  },
});
