
import SitchOrderItem from '@/components/SitchOrderItem.vue';
import { showError } from '@/util-functions/notice-utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: {
    SitchOrderItem,
  },
  props: {
    mode: {
      type: Object as PropType<ShopMode>,
    },
    paymentRecord: {
      type: Object as PropType<GlobalPaymentRecord>,
    },
    currency: {
      type: String,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    orderBreakdown: {
      type: Array as PropType<OrderItem[]>,
    },
  },
  data(): {
    currentOrderItem: OrderItem | null;
    showAdditionalOptionsModal: boolean;
  } {
    return {
      currentOrderItem: null,
      showAdditionalOptionsModal: false,
    };
  },
  computed: {
    computedCurrency(): string {
      return this.currency || this.paymentRecord?.currency || this.mode?.currency || '';
    },
    computedOrderBreakdown(): OrderItem[] {
      return this.orderBreakdown || this.paymentRecord.orderBreakdown;
    },
  },
  methods: {
    onDeleteOrderItemClick(orderItem: OrderItem) {
      if (this.viewOnly) {
        return;
      }
      this.currentOrderItem = orderItem;
      this.showAdditionalOptionsModal = true;
    },
    closeAdditionalOptionsModal() {
      this.currentOrderItem = null;
      this.showAdditionalOptionsModal = false;
    },
    removeOrderItem() {
      const orderBreakdown = this.computedOrderBreakdown.filter((orderItem: OrderItem) => orderItem.id !== (this.currentOrderItem as OrderItem).id);
      this.closeOrderItemDeletionOptionsModal(orderBreakdown);
    },
    removeSingleOrderItem() {
      if (this.viewOnly) {
        return;
      }
      if (!this.currentOrderItem) {
        showError('No order item selected');
        return;
      }
      // Create new order breakdown with modfied quantity.
      const orderBreakdown = this.computedOrderBreakdown.map((oi: OrderItem) => {
        if (oi.id === (this.currentOrderItem as OrderItem).id) {
          const quantity = oi.quantity - 1;
          // Recompute the amounts for the new quantity.
          const totalAmount = oi.totalAmount - oi.amountWithModifiers;

          const ret: OrderItem = {
            ...oi,
            totalAmount,
            quantity,
          };
          return ret;
        }
        return oi;
      });

      this.closeOrderItemDeletionOptionsModal(orderBreakdown);
    },
    closeOrderItemDeletionOptionsModal(orderBreakdown: OrderItem[]) {
      if (!this.viewOnly) {
        this.$emit('change', orderBreakdown);
      }
      this.showAdditionalOptionsModal = false;
      this.currentOrderItem = null;
    },
    getOrderItemMinimumQuantity(orderItem: OrderItem) {
      return orderItem.progenitorMinimumQuantity || 1;
    },
  },
});
