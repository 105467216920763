export default {
  af: {},
  sq: {},
  am: {},
  ar: {},
  hy: {},
  az: {},
  eu: {},
  be: {},
  bn: {},
  bs: {},
  bg: {},
  ca: {},
  zh: {},
  co: {},
  hr: {},
  cs: {},
  da: {},
  nl: {},
  eo: {},
  et: {},
  fi: {},
  fr: {},
  fy: {},
  gl: {},
  ka: {},
  de: {},
  el: {},
  gu: {},
  ht: {},
  ha: {},
  he: {},
  hi: {},
  hu: {},
  is: {},
  ig: {},
  id: {},
  ga: {},
  it: {},
  ja: {},
  jv: {},
  kn: {},
  kk: {},
  km: {},
  rw: {},
  ko: {},
  ku: {},
  ky: {},
  lo: {},
  la: {},
  lv: {},
  lt: {},
  lb: {},
  mk: {},
  mg: {},
  ms: {},
  ml: {},
  mt: {},
  mi: {},
  mr: {},
  mn: {},
  my: {},
  ne: {},
  no: {},
  ny: {},
  or: {},
  ps: {},
  fa: {},
  pl: {},
  pt: {},
  pa: {},
  ro: {},
  ru: {},
  sm: {},
  gd: {},
  sr: {},
  st: {},
  sn: {},
  sd: {},
  si: {},
  sk: {},
  sl: {},
  so: {},
  es: {},
  su: {},
  sw: {},
  sv: {},
  tl: {},
  tg: {},
  ta: {},
  tt: {},
  te: {},
  th: {},
  tr: {},
  tk: {},
  uk: {},
  ur: {},
  ug: {},
  uz: {},
  vi: {},
  cy: {},
  xh: {},
  yi: {},
  yo: {},
  zu: {},
  en: {
    email: `Email`,
    payWithCardOption: `Or pay with a credit card`,
    payWithDigitalWalletOption: `Press here to pay with your digital wallet.`,
    chooseTip: `Choose Tip`,
    payWithCard: `Pay with Card`,
    paymentSuccessful: `Payment Successful`,
    baseAmount: `Pre Total`,
    show: 'Show',
    hide: 'Hide',
    tax: `Tax`,
    tip: `Tip`,
    total: `Total`,
    fee: `Free`,
    other: 'Other %',
    messageSentSuccessfully: 'Your message was sent successfully.',
    submissionSuccessful: 'Submission successful.',
    send: 'Send',
    pickAnOption: 'Pick an Option',
    deliveryAddress: 'Delivery Address',
    additionalAddressDetails: `Additional Address Details (buzzer number, location oddities, etc.)`,
    emailForReceipt: 'Email for receipt',
    changeDeliveryAddress: 'Change Delivery Address',
    checkout: 'Checkout',
    useThisAddress: 'Use this Address',
    mustBeValidEmail: `Must be a valid email`,
    mustBeValidPhoneNumber: `Must be a valid phone number`,
    mustBeValidUrl: `Must be a valid URL`,
    isRequired: `This is required`,
    mustBeLessThan: `Must be less than {0}`,
    validationError: `{0} is invalid. {1}.`,
    makeAnotherPurchase: `Make Another Purchase`,
    error: 'Error',
    isOutOfDeliveryRange: `Must be within {0} km for delivery. You are {1} km away.`,
    userDoesNotExist: 'User does not exist. Recheck URL.',
    thisLinkIsNotYetActivatedTitle: 'Link not activated',
    whenToBeReminded: 'When to be reminded',
    no: 'No',
    yes: 'Yes',
    okay: 'Okay',
    menu: 'Menu',
    readTime: '{0} minute read',
    cannotCompletePayment: 'Cannot complete payment.',
    soldOutThing: 'You ordered {0}x {1}, but there is {2} left in stock.',
    name: 'Name',
    discount: 'Discount',
    promoCode: 'Promo Code',
    promoCodeMessage: 'This promo code gets you {0}% off!',
    promoCodeInvalid: 'This promo code is invalid.',
    notEnoughStockReduceQuantity: 'Not enough stock.',
    interacEmail: 'Email for Interac',
    interacPhoneNumber: 'Phone Number for Interac',
    interacPassword: 'Preferred Interac Password',
    payWithInterac: 'Pay with Interac',
    chargeAmountTooSmall: 'Charge amount is too small',
    needsToBeDelivered: 'Needs to be delivered?',
    info: 'Info',
    shopTypeInfo:
      'If your ordering from home or ordering something large that nees to be shipped, pick the "Deliver" option. If you are with the product/service provider and are just checking out, pick "No"',
    choose: `Choose {0}`,
    mustChoose: `Must choose {0} from the "{1}" section.`,
    mustChooseOrOptOut: `Must choose {0} from the "{1}" section or select the {2} option.`,
    chooseUpTo: `Choose up to {0}`,
    asManyAsYoudLike: `Choose as many as you'd like.`,
    multipleOfSingleModifier: `Repeatedly select an item to increase the quantity.`,
    modifierSoldOut: `This option is sold out.`,
    soldOut: 'Sold Out',
    canOnlySelectMaxModifiers: `Can only select {0}. Unselect another option to pick this one.`,
    quantity: 'Quantity',
    addToCart: 'Add to Cart',
    ageRequirement: 'Age Requirement',
    areYouOverYearsOld: `Are you over {0} years old? You must be at least {0} years old to buy this.`,
    tooYoungToOrder: `You are too young to buy this item`,
    IDontWantAny: `None`,
    IJustWant: `I just want {0}`,
    itemOptions: `Item Options`,
    addedToYourOrder: `{0}x {1} added to your order.`,
    cancel: 'Cancel',
    removeItem: 'Remove Item',
    removeAll: 'Remove All',
    removeOne: 'Remove One',
    yourOrder: 'Your Order',
    yourReceiptHasBeenEmailedToYou: 'Your receipt has been emailed to you.',
    addToYourCalendar: 'Add to your Calendar',
    everyDay: 'Every day',
    every: 'Every {0}',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    pickASlot: 'Pick a Slot',
    confirm: 'Confirm',
    availability: 'Availability',
    selectedSlot: 'Chosen Slot',
    phoneNumber: 'Phone Number',
    bookingSuccessful: 'Booking successful',
    bookingUnsuccessful: 'Could not complete the booking. That slot may have been taken very recently. Refresh and try again.',
    bookingUnsuccessfulMaxSlots: 'Could not complete the booking. Unfortunately, someone else just took the last space for this slot.',
    cancellationSuccessful: 'Booking cancellation successful',
    emailVerfied: 'Email successfully verified. Press the "Email is Verified" button on <a target="_blank" href="https://mysitch.app">mysitch.app</a> or refresh the site to begin using it.',
    cancellationUnsuccessfulNoMode: `Could not cancel the booking, try again later or contact the organization directly.`,
    cancellationUnsuccessful: `Could not cancel the booking. The booking may have already been cancelled. If not, try again later or try contacting the vendor directly via email at {0}.`,
    back: 'Back',
    id: 'ID',
    Questionnaire: 'Questionnaire',
    sendMessageCovidRisk: 'We recommend getting tested for COVID-19 as soon as possble',
    sendMessageCovidIsolation: 'Based on your responses self-isolation for 14 days is recommended.',
    mustAnswerAllQuestions: 'Must answer all the questions in the questionnaire.',
    doYouHaveSymptoms: 'Do you have any of the following symptoms?',
    symptom1: 'Fever (a temperature of 37.8 degrees celcius or higher)',
    symptom2: 'Chills',
    symptom3: 'New or worsening cough',
    symptom4: 'Barking cough, making whistling noise when breathing (croup)',
    symptom5: 'Shortness of breath (unable to breathe deeply or feeling unable to catch one’s breath)',
    symptom6: 'Sore throat (not related to seasonal allergies or other known conditions)',
    symptom7: 'Trouble/Difficulty swallowing',
    symptom8: 'Runny nose, or nasal congestion (not related to seasonal allergies or other known causes or conditions)',
    symptom9: 'Unusual headache',
    symptom10: 'Changes in taste or smell (e.g. lost sense of taste)',
    symptom11: 'Nausea or vomiting (not related to other known causes or conditions)',
    symptom12: 'Diarrhea or stomach pain (not related to other known causes or conditions)',
    symptom13: 'Unexplained fatigue (lack of energy)',
    symptom14: 'Sore muscles (long lasting or unusual)',
    symptom15: 'Pink eye (irritated, itchy or painful eye that may have crusting or discharge; conjunctivitis)',
    symptom16: 'Falling down often',
    symptom17: 'Sluggishness or lack of appetite (note these symptoms may be more relevant to infants and young children)',
    covidQuestion1: 'Have you traveled outside of the country in the last 14 days?',
    covidQuestion2: 'Have you been in close physical contact with someone who has tested positive for COVID-19 in the past 14 days?',
    covidQuestion3:
      'Have your household contacts (family members and/or roommates) or other contacts outside of school/child care presented with new COVID-19 symptoms (like a cough, fever, or difficulty breathing) in the last 14 days?',
    bookingWith: 'Booking with {0}',
    referenceId: `Order ID`,
    paymentIntentId: `Payment ID`,
    statementDescriptor: `Statement Descriptor`,
    businessEmail: `Business Email`,
    businessUrl: `Business Website`,
    businessName: `Business Name`,
    creditCardLastFour: `Last 4 digits of Payment Card`,
    chargeStatus: `Charge Status`,
    reset: 'Reset',
    stripeReceipt: `Stripe Receipt`,
    clickHere: `Press here`,
    transactionSummary: `Transaction Summary`,
    invalidTransactionId: 'Could not find the transaction record. Invalid transaction ID.',
    date: 'Date',
    noneSelected: 'None selected',
    language: 'Language',
    share: 'Share',
    message: 'Message',
    createYourOwn: 'Wanna create your own Sitch? Sign up <a target="_blank" href="https://mysitch.app">here.</a>',
    otherPosts: 'Other Posts',
    address: 'Address',
    yourCartIsEmpty: 'Your cart is empty.',
    thankYouForYourPurchase: 'Thank you for your purchase.',
    flatDeliveryFee: 'Delivery',
    addSpecialRequest: `Add Special Request`,
    specialRequest: `Special Request`,
    setReminder: 'Set reminder?',
    byEmail: 'By email',
    byNotification: 'By notification to this device',
    notifications: 'Notifications',
    requestingNotificationsInfo: 'To perform this action you will have to enable push notification permissions.',
    reminderWasSetSuccessfully: 'Reminder set.',
    setReminderConfirm: 'Set Reminder',
    loading: 'Loading...',
    couldNotEnableNotifications: 'Could not enable notifcations. You may have notifications from sitch.app blocked. You can change this in your browsers settings.',
    minutesPrior: '{0} minutes prior at {1} on {2}',
    oneHourPrior: 'About {0} hour prior at {1} on {2}',
    hoursPrior: 'About {0} hours prior at {1} on {2}',
    changeSlot: 'Change Slot',
    deprecatedModeMessage: 'This Sitch type is no longer supported.',
    sendingPaymentDoNotClose: 'Sending payment, do not close this window.',
    costTooLow: 'The total order cost is too low to enable checkout.',
    refundAmount: 'Refunded',
    belowMinimumChargeAmount: 'Below minimum charge amount.',
    showPaymentOptions: 'Payment Options',
    invalidEmail: 'Invalid email',
    paymentWith: 'Payment with {0}',
    thingRequired: '{0} required.',
    review: 'Review',
    shortText: `Short Text`,
    longText: `Long Text`,
    number: `Number`,
    checkbox: `Checkbox`,
    multipleChoice: 'Multiple Choice',
    fullScreen: 'Fullscreen',
    shrink: 'Shrink',
    invalidCreditCardInfo: 'Invalid credit card information',
    addressRequired: 'Address required',
    amountToPay: 'The amount to pay is {0}',
    image: 'Image',
    messageOptions: 'Message Options',
    deleteMessage: 'Delete Message',
    thatNameIsInUse: 'That name is alrady in use',
    yourNewName: 'Your new name',
    noOneIsHereYet: 'No one is here yet.',
    chatAtMaxCapacity: 'Chat is at max capacity.',
    typeAMessage: 'Type a message',
    getPushNotifications: 'Get Notifications',
    stopPushNotifications: 'Stop Notifications',
    thisChatWasDeleted: 'This chat was deleted.',
    hasRejoinedtheChat: '{0} has rejoined the chat.',
    hasJoinedtheChat: '{0} has joined the chat.',
    hasLeftTheChat: '{0} has left the chat',
    youHaveLeftTheChat: 'You have left the chat.',
    leave: 'Leave',
    rejoin: 'Rejoin',
    noName: 'No name',
    youAreOnline: 'You are online',
    youAreOffline: 'You are offline',
    participants: 'Participants',
    userJoinedOn: '{0} joined {1}',
    nowRecording: 'Now recording.',
    recordingEnded: 'Recording ended.',
    addToContacts: 'Add to contacts?',
    payment: 'Payment',
    needApplePay: `Need Apple Pay? Press the "Show Apple Pay" button to open this shop in a new tab. We'll transfer your information and shopping cart over as well.`,
    showApplePay: 'Show Apple Pay',
    editImage: 'Edit Image',
    done: 'Done',
    replace: 'Replace',
    delete: 'Delete',
    customLogo: 'Custom Logo',
    mustUploadALogo: 'You must upload a logo.',
    resetGame: 'Reset Game',
    areYouSureYouWantToReset: 'Are you sure you want to reset the game?',
    check: '{0} in check.',
    checkmate: 'Checkmate',
    stalemate: 'Stalemate',
    draw: 'Draw',
    gameOverMessage: '{0}. Reset the game?',
    undo: 'Undo',
    black: 'Black',
    white: 'White',
    pickSide: 'Pick Side',
    turn: `{0} to play.`,
    showThreats: 'Show Threats',
    hideThreats: 'Hide Threats',
    moves: 'Moves: {0}',
    moveUndo: 'The last move was undone.',
    paymentFailed: 'Payment failed. You were not charged.',
    filesMax: `Cannot upload more than {0} files.`,
    fileTooLarge: 'File too large. Must be less than 10MB.',
    durationBoth: '{0} hr, {1} min',
    durationHoursOnly: '{0} hr',
    durationMinutesOnly: '{0} min',
    noModeFound: `Looks like there's nothing here. Either the link is invalid, the Sitch was deleted, or there is no active Sitch. To create a Sitch, sign in at <a href="https://mysitch.app">mysitch.app</a>.`,
    noActiveSitch: 'No Active Sitch',
    copyBitcoinAddress: 'Copy Bitcoin Address',
    copyEthereumAddress: 'Copy Ethereum Address',
    copyLitecoinAddress: 'Copy Litecoin Address',
    optional: 'optional',
    save: 'Save',
    signHere: 'Sign Here',
    clear: 'Clear',
    signaturePadInfo: 'Draw your signature below.',
    addressInstructions: 'Type in an address below and select an option from the dropdown.',
    incompleteAddress: 'Incomplete Address',
    editMessage: 'Edit Message',
    addToContactsDescription: 'Would you like to add {0} to your contacts?',
    sitchLogoUploadText: `Light colored backgrounds are preferred for print quality (transparent or white backgrounds are best). Subtle gradients can be hit or miss, but we'll communicate and work with you for any challenging prints.`,
    shareContactInfo: 'Share Your Info',
    recent: 'Recent',
    seeRecentlyViewedModes: `See recently viewed Sitches here. You can visit sitch.app to see this list at any time.`,
    welcomeToSitch: 'Sitch is a platform for sharing digital utilities quickly and easily.',
    login: 'Login',
    recentModes: 'Recent Sitches',
    removingPartcipants: 'Participants can be removed by editing this Sitch on mysitch.app.',
    thisLinkIsNotYetActivatedDescription:
      'This link has not yet been activated for physical QR/NFC usage. To activate it purchase a Stich Device and bind it to your account. This Sitch can still be shared via the in app QR code instead.',
    bookTime: 'Book Time',
    pay: 'Pay',
    portfolio: 'Portfolio',
    resume: 'Resume',
    generic: 'Generic',
    shop: 'Shop',
    cannotUseThatWord: 'Cannot use that word',
    gameOver: 'Game Over',
    wins: '{0} wins!',
    tied: 'Tied for the win: {0}!',
    correct: 'Correct.',
    incorrect: 'Incorrect.',
    pcKeyboard: 'Use PC Keyboard',
    wordleRules: 'First to {0} points wins. Correct answers are +1 and incorrect answers are -1.',
    triviaRules: 'Whoever scores the most out of {0} questions wins.',
    sitchWasDeleted: 'This Sitch was deleted',
    notice: 'Notice',
    pickPlayer: 'Enter as Player {0}',
    youWereRemovedFromTheGame: 'You were removed from the game.',
    active: 'Active',
    areYouSureYouWantToRemovePlayer: 'Are you sure you want to remove {0} due to inactivity?',
    joinGame: 'Join Game',
    theGameWasReset: 'The game was reset',
    areYouSureYouWantToLeaveTheGame: 'Are you sure you want to leave the game?',
    maxPlayers: 'Cannot join. At maximum of {0} players.',
    question: 'Question {0}',
    tapToOpen: 'Tap to open.',
    saveForLater: 'Save for Later',
    saveForLaterInfo: `You can always see the last 50 Sitches you've viewed at <a target="_blank" href="https://sitch.app">sitch.app</a>. Alternatively, you can share this link to yourself to save it wherever you'd like.`,
    shareToYourself: 'Share to Yourself',
    deliveryFormMustBeCompleted: 'Address is incomplete. Press "Use this Address" when you are done.',
    poweredBy: `Powered by Sitch. Check us out <a target="_blank" href="https://sitch.cards">here</a>.`,
    addContact: 'Add to Contacts',
    getCardTemplate: 'Get Design Template',
    getCardTemplateInfo:
      '<a target="_blank" href="https://photopea.com#iEfW62KXu">Click here to use Photopea</a> to design your card, then upload it here after your done. Alternatively, download our design template to create your design with your preferred image editing software. For non-color prints, be sure to upload a simple black-on-white monochrome design for the best results.',
    uploadDesign: 'Upload Design',
    rotateLeft: 'Left',
    rotateRight: 'Right',
    cardPreview: 'Card Preview',
    clickToFlip: 'Click to flip.',
    addressLine1: 'Street Address',
    addressLine2: `Unit/Apartment/Suite/Other (Optional)`,
    city: 'City',
    country: 'Country',
    enterAddressManually: 'Enter address manually instead',
    enterYourAddress: `Start typing`,
    noAutocomplete: `Type in an address and select an option from the dropdown. Press 'Use This Address' when you're finished.`,
    shareQRCode: 'Share to Google Lens',
    accessWifi: `To join the WiFi, share the QR code to Google Lens or a similar QR scanning app. Alternatively, use the credentials below.`,
    deliveryFee: 'Delivery',
    webReceipt: 'Web Receipt',
    invoice: 'Invoice',
    wouldYouLikeToShareYourInfo: 'Would you also like to share your info?',
    logoSideForegroundColor: 'Front Foreground Color',
    for: '{0} for item {1}',
    nothing: 'Nothing',
    noActiveSitchInfo: 'This card has been paired with a Sitch account with no an active Sitch. Create a Sitch at <a target="_blank" href="https://mysitch.app">mysitch.app</a> to show it here.',
    learnMore: 'Learn More at https://sitch.cards',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',    
    search: 'Search',
    province: 'Province',
    state: 'State',    
    postalCode: 'Zip / Postal Code',
  },
};
