import Vue from 'vue';
import { eSnackBarTypes } from '@/enums';
import { store } from '@/store';
import { addDoc, arrayUnion, collection, serverTimestamp } from 'firebase/firestore';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { currFirestore, isProductionEnv, t } from './initialization-util';

export const showConfirmation = (title: string, msg: string, onConfirmFunc = (): void => undefined, onCancelFunc = (): void => undefined) => {
  Vue.nextTick(() => {
    // Added a next tick for instances where modals are instantly closed on route changes.
    store.state.rootComponent.showConfirmationModalFunc(title, msg, onConfirmFunc, onCancelFunc);
  });
};

export const showGlobalModal = (title: string, msg: string) => {
  Vue.nextTick(() => {
    // Added a next tick for instances where modals are instantly closed on route changes.
    if (store.state.rootComponent) {
      store.state.rootComponent.showGlobalModalFunc(title, msg);
    } else {
      alert(msg);
    }
  });
};

export const showError = (str: string | undefined, report = false, errorObject = null) => {
  // Temporary workaround on not showing this error for the time being.
  if (!str || str.includes('get-stripe-subscription')) {
    return;
  }
  if (report) {
    addDoc(collection(currFirestore, 'clientErrorLog'), {
      errors: arrayUnion({
        date: Date.now(),
        path: window.location.pathname,
        error: str || '',
        errorObject: errorObject || null,
      }),
      dateUpdated: serverTimestamp(),
    });
  }
  console.error(str);
  if (!store.state.rootComponent || !store.state.rootComponent.isMounted) {
    alert(str);
    return;
  }
  store.state.rootComponent.showSnackBarFunc(str, eSnackBarTypes.error);
};

export const showNotice = (str: string) => {
  if (!store.state.rootComponent || !store.state.rootComponent.isMounted || !str) {
    return;
  }
  store.state.rootComponent.showSnackBarFunc(str, eSnackBarTypes.notice);
};

export const showSuccess = (str: string) => {
  if (!store.state.rootComponent || !store.state.rootComponent.isMounted || !str) {
    return;
  }
  store.state.rootComponent.showSnackBarFunc(str, eSnackBarTypes.success);
};

export const showWarning = (str: string) => {
  if (!store.state.rootComponent || !store.state.rootComponent.isMounted || !str) {
    return;
  }
  store.state.rootComponent.showSnackBarFunc(str, eSnackBarTypes.warning);
};

export const setUpNotifications = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      reject(null);
      return; // Push notifications not supported in this case.
    }

    const requestNotificationsPermission = async () => {
      const testWebPushKeyPair = 'BM4zVMJivjp_pC39qtcOFlQZFsE35AiP4dZm_TecDNR3NjLB3p6J07VsPE3882WSn_cyUoM8R1qPCzcMH5hFL3s';
      const prodWebPushKeyPair = 'BBqiyjLhnO-ka7XuQboCLcMwuPRIFSPlwJPts_8_RJxS3dIDAdjr9JuNMBmW2NiDHTPiCHhlz5tRwDev6A4iO2o';

      const messagingSupported = await isSupported();

      if (!messagingSupported) {
        console.error('Firebase messaging not supported.');
        reject(null);
        return;
      }

      const messaging = getMessaging();

      const getTokenFunc = () => {
        getToken(messaging, { vapidKey: isProductionEnv ? prodWebPushKeyPair : testWebPushKeyPair })
          .then((pushToken) => {
            if (pushToken) {
              store.commit('pushToken', pushToken);
              resolve(pushToken);
            }
          })
          .catch((error) => {
            console.error(`An error occurred while retrieving token: ${error}`);
            reject(null);
          });
      };

      Notification.requestPermission()
        .then(() => {
          getTokenFunc();
        })
        .catch((error) => {
          console.error(`No permission given for push notifications: ${error}`);
          reject(null);
        });
    };

    navigator.permissions.query({ name: 'notifications' }).then((result) => {
      switch (result.state) {
        case 'granted':
          requestNotificationsPermission();
          break;
        case 'prompt':
          showConfirmation(t.notifications, t.requestingNotificationsInfo, () => {
            requestNotificationsPermission();
          });
          break;
        case 'denied':
          // Do nothing.
          reject(null);
          break;
      }
    });
  });
};
