export default {
  af: {
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  sq: {
    name: 'Albanian',
    nativeName: 'Shqip',
  },
  am: {
    name: 'Amharic',
    nativeName: 'አማርኛ',
  },
  ar: {
    name: 'Arabic',
    nativeName: 'العربية',
  },
  hy: {
    name: 'Armenian',
    nativeName: 'Հայերեն',
  },
  az: {
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  eu: {
    name: 'Basque',
    nativeName: 'euskara, euskera',
  },
  be: {
    name: 'Belarusian',
    nativeName: 'Беларуская',
  },
  bn: {
    name: 'Bengali',
    nativeName: 'বাংলা',
  },
  bs: {
    name: 'Bosnian',
    nativeName: 'bosanski jezik',
  },
  bg: {
    name: 'Bulgarian',
    nativeName: 'български език',
  },
  ca: {
    name: 'Catalan',
    nativeName: 'Català',
  },
  zh: {
    name: 'Chinese',
    nativeName: '中文, 汉语, 漢語',
  },
  co: {
    name: 'Corsican',
    nativeName: 'corsu',
  },
  hr: {
    name: 'Croatian',
    nativeName: 'hrvatski',
  },
  cs: {
    name: 'Czech',
    nativeName: 'česky, čeština',
  },
  da: {
    name: 'Danish',
    nativeName: 'dansk',
  },
  nl: {
    name: 'Dutch',
    nativeName: 'Nederlands',
  },
  en: {
    name: 'English',
    nativeName: 'English',
  },
  eo: {
    name: 'Esperanto',
    nativeName: 'Esperanto',
  },
  et: {
    name: 'Estonian',
    nativeName: 'eesti',
  },
  fi: {
    name: 'Finnish',
    nativeName: 'suomi',
  },
  fr: {
    name: 'French',
    nativeName: 'français',
  },
  fy: {
    name: 'Frisian',
    nativeName: 'Frysk',
  },
  gl: {
    name: 'Galician',
    nativeName: 'Galego',
  },
  ka: {
    name: 'Georgian',
    nativeName: 'ქართული',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
  },
  el: {
    name: 'Greek',
    nativeName: 'Ελληνικά',
  },
  gu: {
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  ht: {
    name: 'Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  ha: {
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  he: {
    name: 'Hebrew',
    nativeName: 'עברית',
  },
  hi: {
    name: 'Hindi',
    nativeName: 'हिन्दी, हिंदी',
  },
  hu: {
    name: 'Hungarian',
    nativeName: 'Magyar',
  },
  is: {
    name: 'Icelandic',
    nativeName: 'Íslenska',
  },
  ig: {
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  id: {
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  ga: {
    name: 'Irish',
    nativeName: 'Gaeilge',
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano',
  },
  ja: {
    name: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  jv: {
    name: 'Javanese',
    nativeName: 'basa Jawa',
  },
  kn: {
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  kk: {
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
  },
  km: {
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  rw: {
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  ko: {
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  ku: {
    name: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎',
  },
  ky: {
    name: 'Kyrgyz',
    nativeName: 'кыргыз тили',
  },
  lo: {
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
  },
  la: {
    name: 'Latin',
    nativeName: 'latine, lingua latina',
  },
  lv: {
    name: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  lt: {
    name: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  lb: {
    name: 'Luxembourgish',
    nativeName: 'Lëtzebuergesch',
  },
  mk: {
    name: 'Macedonian',
    nativeName: 'македонски јазик',
  },
  mg: {
    name: 'Malagasy',
    nativeName: 'Malagasy fiteny',
  },
  ms: {
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  ml: {
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  mt: {
    name: 'Maltese',
    nativeName: 'Malti',
  },
  mi: {
    name: 'Maori',
    nativeName: 'te reo Māori',
  },
  mr: {
    name: 'Marathi',
    nativeName: 'मराठी',
  },
  mn: {
    name: 'Mongolian',
    nativeName: 'монгол',
  },
  my: {
    name: 'Myanmar (Burmese)',
    nativeName: 'ဗမာစာ',
  },
  ne: {
    name: 'Nepali',
    nativeName: 'नेपाली',
  },
  no: {
    name: 'Norwegian',
    nativeName: 'Norsk',
  },
  ny: {
    name: 'Nyanja (Chichewa)',
    nativeName: 'chiCheŵa, chinyanja',
  },
  or: {
    name: 'Odia (Oriya)',
    nativeName: 'ଓଡ଼ିଆ',
  },
  ps: {
    name: 'Pashto',
    nativeName: 'پښتو',
  },
  fa: {
    name: 'Persian',
    nativeName: 'فارسی',
  },
  pl: {
    name: 'Polish',
    nativeName: 'polski',
  },
  pt: {
    name: 'Portuguese (Portugal, Brazil)',
    nativeName: 'Português',
  },
  pa: {
    name: 'Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  ro: {
    name: 'Romanian',
    nativeName: 'română',
  },
  ru: {
    name: 'Russian',
    nativeName: 'русский язык',
  },
  sm: {
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
  },
  gd: {
    name: 'Scots Gaelic',
    nativeName: 'Gàidhlig',
  },
  sr: {
    name: 'Serbian',
    nativeName: 'српски језик',
  },
  st: {
    name: 'Sesotho',
    nativeName: 'Sesotho',
  },
  sn: {
    name: 'Shona',
    nativeName: 'chiShona',
  },
  sd: {
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  si: {
    name: 'Sinhala (Sinhalese)',
    nativeName: 'සිංහල',
  },
  sk: {
    name: 'Slovak',
    nativeName: 'slovenčina',
  },
  sl: {
    name: 'Slovenian',
    nativeName: 'slovenščina',
  },
  so: {
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  es: {
    name: 'Spanish',
    nativeName: 'español, castellano',
  },
  su: {
    name: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  sw: {
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  sv: {
    name: 'Swedish',
    nativeName: 'svenska',
  },
  tl: {
    name: 'Tagalog (Filipino)',
    nativeName: 'Wikang Tagalog',
  },
  tg: {
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  ta: {
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  tt: {
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  te: {
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  th: {
    name: 'Thai',
    nativeName: 'ไทย',
  },
  tr: {
    name: 'Turkish',
    nativeName: 'Türkçe',
  },
  tk: {
    name: 'Turkmen',
    nativeName: 'Türkmen, Түркмен',
  },
  uk: {
    name: 'Ukrainian',
    nativeName: 'українська',
  },
  ur: {
    name: 'Urdu',
    nativeName: 'اردو',
  },
  ug: {
    name: 'Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  uz: {
    name: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  vi: {
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  cy: {
    name: 'Welsh',
    nativeName: 'Cymraeg',
  },
  xh: {
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  yi: {
    name: 'Yiddish',
    nativeName: 'ייִדיש',
  },
  yo: {
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  zu: {
    name: 'Zulu',
    nativeName: 'Zulu',
  },
};
