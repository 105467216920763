import { getLocalStorageItem, setLocalStorageItem, store } from '@/store';
import { doc, setDoc, increment } from 'firebase/firestore';
import { currFirestore, isProductionEnv } from './initialization-util';
import { format as dateFnsFormat } from 'date-fns';

export const getInteractionData = (clickedObject: any): InteractionData => {
  const date = new Date();
  return {
    viewCount: increment(1),
    name: clickedObject.name || clickedObject.url || '',
    dayOfWeekViewCounts: {
      [date.getDay()]: increment(1),
    },
    hourOfDayViewCounts: {
      [date.getHours()]: increment(1),
    },
    monthOfYearViewCounts: {
      [date.getMonth()]: increment(1),
    },
    yearViewCounts: {
      [date.getFullYear()]: increment(1),
    },
  };
};

export const analyzeClick = (mode: AnyMode, clickedObject: AnalyzableObject) => {
  if (store.state.noTrack) {
    return;
  }
  if (isProductionEnv) {
    setDoc(
      doc(currFirestore, 'users', store.state.userId, 'analytics', mode.docId),
      {
        interactionAnalytics: {
          [clickedObject.id]: getInteractionData(clickedObject),
        },
      },
      { merge: true }
    );
  }
};

export const incrementUniqueViews = (userId: string) => {
  if (store.state.noTrack) {
    return;
  }
  const localStorageKey = 'viewedUserIds';
  const viewedUserIdsStringValue = getLocalStorageItem(localStorageKey);
  // Get the array if unique user IDs we've seen if it exists, otherwise we'll start with an empty array
  const viewedUserIds = viewedUserIdsStringValue ? JSON.parse(viewedUserIdsStringValue) : [];
  // Only make an update if this is a user who has seen this user ID for the very first time.
  if (!viewedUserIds.includes(userId)) {
    viewedUserIds.push(userId);
    // Set the array with the current userId added.
    setLocalStorageItem(localStorageKey, JSON.stringify(viewedUserIds));
    const date = new Date();
    const update: any = {
      ids: {
        [store.state.genericId || userId]: increment(1),
      },
      deviceTypes: {
        [store.state.interactionMethod]: increment(1), // Track unqiue views by device type.
      },
      uniqueViewsByMonth: {
        [dateFnsFormat(date, 'yyyy-MM')]: increment(1), // Track unqiue views per id for each month.
      },
      uniqueDeviceViewsByMonth: {
        [dateFnsFormat(date, 'yyyy-MM')]: store.state.genericId ? increment(1) : increment(0), // Track unqiue views per id for each month.
      },
      uniqueViews: increment(1), // Track all unique views.
      deviceViews: store.state.genericId ? increment(1) : increment(0), // Track unique views via a device and not just a link.
    };
    setDoc(doc(currFirestore, 'users', userId, 'uniqueViews', 'uniqueViewsDoc'), update, { merge: true });
  }
};
