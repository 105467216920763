import { render, staticRenderFns } from "./SitchAvatar.vue?vue&type=template&id=0826a7df&scoped=true&"
import script from "./SitchAvatar.vue?vue&type=script&lang=ts&"
export * from "./SitchAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./SitchAvatar.vue?vue&type=style&index=0&id=0826a7df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0826a7df",
  null
  
)

export default component.exports