
import { storeEventBus } from '@/store';
import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    initiallyActive: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showContent: false,
    };
  },

  mounted() {
    this.$data.content = this.$refs.content;
    storeEventBus.$on('closeAccordions', this.onCloseAccordion);
    if (this.$props.initiallyActive) {
      this.$data.showContent = true;
    }
  },
  watch: {
    $route() {
      if (this.$props.initiallyActive) {
        this.$data.showContent = true;
      }
    },
  },
  methods: {
    onLabelClick() {
      if (this.$data.showContent) {
        this.onCloseAccordion();
      } else {
        storeEventBus.$emit('closeAccordions');
        this.$data.showContent = true;
      }
    },
    onCloseAccordion() {
      this.$data.showContent = false;
    },
  },
});
